    import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
const SuccessStripePage = () => {
        const history =  useHistory()
        return (
            <div  style = {{display:"flex",top:0,left:0,bottom:0,right:0,alignItems:"center",flexDirection:"column"}}>
                Thank you for subscribing! You plan will activated within the next 15 mins.
                 <Button variant="contained" color="primary" onClick = {()=>{history.push('/businessLogin')}} >Click here to go back</Button>
            </div>
        )
    }
    
    export default SuccessStripePage
    