    import React from 'react'
import CreateMenu from './CreateMenu'
import { useSelector } from 'react-redux';
    
const MenuEditCreate = (props) => {
    let shortcode = props.match.params.short_code;
    
    // const currentMenu = useSelector(state => state.menuData.currentMenus.filter(menu=>menu.short_code === shortcode));

    console.log(shortcode);
   
        return (
            <div>
            <CreateMenu  edit={shortcode==='new'?false:true} ></CreateMenu>
            {/* {goBackButton ? <Button variant="contained" color="primary" style={{
                position: "fixed", top: 5, left: 5, zIndex: 2000
            }} onClick={() => { history.go() }}>GO BACK</Button> : null} */}
        </div>
                
            
        )
    }
    
    export default MenuEditCreate
    