const languages = [
    {
        "639-1": "aa",
        "639-2/T": "aar",
        "639-2/B": "aar",
        "language": "Afar",
        "Native name": "Afaraf"
    },
    {
        "639-1": "ab",
        "639-2/T": "abk",
        "639-2/B": "abk",
        "language": "Abkhaz",
        "Native name": "аҧсуа бызшәа, аҧсшәа"
    },
    {
        "639-1": "ae",
        "639-2/T": "ave",
        "639-2/B": "ave",
        "language": "Avestan",
        "Native name": "avesta"
    },
    {
        "639-1": "af",
        "639-2/T": "afr",
        "639-2/B": "afr",
        "language": "Afrikaans",
        "Native name": "Afrikaans"
    },
    {
        "639-1": "ak",
        "639-2/T": "aka",
        "639-2/B": "aka",
        "language": "Akan",
        "Native name": "Akan"
    },
    {
        "639-1": "am",
        "639-2/T": "amh",
        "639-2/B": "amh",
        "language": "Amharic",
        "Native name": "አማርኛ"
    },
    {
        "639-1": "an",
        "639-2/T": "arg",
        "639-2/B": "arg",
        "language": "Aragonese",
        "Native name": "aragonés"
    },
    {
        "639-1": "ar",
        "639-2/T": "ara",
        "639-2/B": "ara",
        "language": "Arabic",
        "Native name": "العربية"
    },
    {
        "639-1": "as",
        "639-2/T": "asm",
        "639-2/B": "asm",
        "language": "Assamese",
        "Native name": "অসমীয়া"
    },
    {
        "639-1": "av",
        "639-2/T": "ava",
        "639-2/B": "ava",
        "language": "Avaric",
        "Native name": "авар мацӀ, магӀарул мацӀ"
    },
    {
        "639-1": "ay",
        "639-2/T": "aym",
        "639-2/B": "aym",
        "language": "Aymara",
        "Native name": "aymar aru"
    },
    {
        "639-1": "az",
        "639-2/T": "aze",
        "639-2/B": "aze",
        "language": "Azerbaijani",
        "Native name": "azərbaycan dili"
    },
    {
        "639-1": "az",
        "639-2/T": "azb",
        "639-2/B": "azb",
        "language": "South Azerbaijani",
        "Native name": "تورکجه‎"
    },
    {
        "639-1": "ba",
        "639-2/T": "bak",
        "639-2/B": "bak",
        "language": "Bashkir",
        "Native name": "башҡорт теле"
    },
    {
        "639-1": "be",
        "639-2/T": "bel",
        "639-2/B": "bel",
        "language": "Belarusian",
        "Native name": "беларуская мова"
    },
    {
        "639-1": "bg",
        "639-2/T": "bul",
        "639-2/B": "bul",
        "language": "Bulgarian",
        "Native name": "български език"
    },
    {
        "639-1": "bh",
        "639-2/T": "bih",
        "639-2/B": "bih",
        "language": "Bihari",
        "Native name": "भोजपुरी"
    },
    {
        "639-1": "bi",
        "639-2/T": "bis",
        "639-2/B": "bis",
        "language": "Bislama",
        "Native name": "Bislama"
    },
    {
        "639-1": "bm",
        "639-2/T": "bam",
        "639-2/B": "bam",
        "language": "Bambara",
        "Native name": "bamanankan"
    },
    {
        "639-1": "bn",
        "639-2/T": "ben",
        "639-2/B": "ben",
        "language": "Bengali; Bangla",
        "Native name": "বাংলা"
    },
    {
        "639-1": "bo",
        "639-2/T": "bod",
        "639-2/B": "tib",
        "language": "Tibetan Standard, Tibetan, Central",
        "Native name": "བོད་ཡིག"
    },
    {
        "639-1": "br",
        "639-2/T": "bre",
        "639-2/B": "bre",
        "language": "Breton",
        "Native name": "brezhoneg"
    },
    {
        "639-1": "bs",
        "639-2/T": "bos",
        "639-2/B": "bos",
        "language": "Bosnian",
        "Native name": "bosanski jezik"
    },
    {
        "639-1": "ca",
        "639-2/T": "cat",
        "639-2/B": "cat",
        "language": "Catalan; Valencian",
        "Native name": "català, valencià"
    },
    {
        "639-1": "ce",
        "639-2/T": "che",
        "639-2/B": "che",
        "language": "Chechen",
        "Native name": "нохчийн мотт"
    },
    {
        "639-1": "ch",
        "639-2/T": "cha",
        "639-2/B": "cha",
        "language": "Chamorro",
        "Native name": "Chamoru"
    },
    {
        "639-1": "co",
        "639-2/T": "cos",
        "639-2/B": "cos",
        "language": "Corsican",
        "Native name": "corsu, lingua corsa"
    },
    {
        "639-1": "cr",
        "639-2/T": "cre",
        "639-2/B": "cre",
        "language": "Cree",
        "Native name": "ᓀᐦᐃᔭᐍᐏᐣ"
    },
    {
        "639-1": "cs",
        "639-2/T": "ces",
        "639-2/B": "cze",
        "language": "Czech",
        "Native name": "čeština, český jazyk"
    },
    {
        "639-1": "cu",
        "639-2/T": "chu",
        "639-2/B": "chu",
        "language": "Old Church Slavonic, Church Slavonic, Old Bulgarian",
        "Native name": "ѩзыкъ словѣньскъ"
    },
    {
        "639-1": "cv",
        "639-2/T": "chv",
        "639-2/B": "chv",
        "language": "Chuvash",
        "Native name": "чӑваш чӗлхи"
    },
    {
        "639-1": "cy",
        "639-2/T": "cym",
        "639-2/B": "wel",
        "language": "Welsh",
        "Native name": "Cymraeg"
    },
    {
        "639-1": "da",
        "639-2/T": "dan",
        "639-2/B": "dan",
        "language": "Danish",
        "Native name": "dansk"
    },
    {
        "639-1": "de",
        "639-2/T": "deu",
        "639-2/B": "ger",
        "language": "German",
        "Native name": "Deutsch"
    },
    {
        "639-1": "dv",
        "639-2/T": "div",
        "639-2/B": "div",
        "language": "Divehi; Dhivehi; Maldivian;",
        "Native name": "ދިވެހި"
    },
    {
        "639-1": "dz",
        "639-2/T": "dzo",
        "639-2/B": "dzo",
        "language": "Dzongkha",
        "Native name": "རྫོང་ཁ"
    },
    {
        "639-1": "ee",
        "639-2/T": "ewe",
        "639-2/B": "ewe",
        "language": "Ewe",
        "Native name": "Eʋegbe"
    },
    {
        "639-1": "el",
        "639-2/T": "ell",
        "639-2/B": "gre",
        "language": "Greek, Modern",
        "Native name": "ελληνικά"
    },
    {
        "639-1": "en",
        "639-2/T": "eng",
        "639-2/B": "eng",
        "language": "English",
        "Native name": "English"
    },
    {
        "639-1": "eo",
        "639-2/T": "epo",
        "639-2/B": "epo",
        "language": "Esperanto",
        "Native name": "Esperanto"
    },
    {
        "639-1": "es",
        "639-2/T": "spa",
        "639-2/B": "spa",
        "language": "Spanish; Castilian",
        "Native name": "español, castellano"
    },
    {
        "639-1": "et",
        "639-2/T": "est",
        "639-2/B": "est",
        "language": "Estonian",
        "Native name": "eesti, eesti keel"
    },
    {
        "639-1": "eu",
        "639-2/T": "eus",
        "639-2/B": "baq",
        "language": "Basque",
        "Native name": "euskara, euskera"
    },
    {
        "639-1": "fa",
        "639-2/T": "fas",
        "639-2/B": "per",
        "language": "Persian (Farsi)",
        "Native name": "فارسی"
    },
    {
        "639-1": "ff",
        "639-2/T": "ful",
        "639-2/B": "ful",
        "language": "Fula; Fulah; Pulaar; Pular",
        "Native name": "Fulfulde, Pulaar, Pular"
    },
    {
        "639-1": "fi",
        "639-2/T": "fin",
        "639-2/B": "fin",
        "language": "Finnish",
        "Native name": "suomi, suomen kieli"
    },
    {
        "639-1": "fj",
        "639-2/T": "fij",
        "639-2/B": "fij",
        "language": "Fijian",
        "Native name": "vosa Vakaviti"
    },
    {
        "639-1": "fo",
        "639-2/T": "fao",
        "639-2/B": "fao",
        "language": "Faroese",
        "Native name": "føroyskt"
    },
    {
        "639-1": "fr",
        "639-2/T": "fra",
        "639-2/B": "fre",
        "language": "French",
        "Native name": "français, langue française"
    },
    {
        "639-1": "fy",
        "639-2/T": "fry",
        "639-2/B": "fry",
        "language": "Western Frisian",
        "Native name": "Frysk"
    },
    {
        "639-1": "ga",
        "639-2/T": "gle",
        "639-2/B": "gle",
        "language": "Irish",
        "Native name": "Gaeilge"
    },
    {
        "639-1": "gd",
        "639-2/T": "gla",
        "639-2/B": "gla",
        "language": "Scottish Gaelic; Gaelic",
        "Native name": "Gàidhlig"
    },
    {
        "639-1": "gl",
        "639-2/T": "glg",
        "639-2/B": "glg",
        "language": "Galician",
        "Native name": "galego"
    },
    {
        "639-1": "gn",
        "639-2/T": "grn",
        "639-2/B": "grn",
        "language": "Guaraní",
        "Native name": "Avañe'ẽ"
    },
    {
        "639-1": "gu",
        "639-2/T": "guj",
        "639-2/B": "guj",
        "language": "Gujarati",
        "Native name": "ગુજરાતી"
    },
    {
        "639-1": "gv",
        "639-2/T": "glv",
        "639-2/B": "glv",
        "language": "Manx",
        "Native name": "Gaelg, Gailck"
    },
    {
        "639-1": "ha",
        "639-2/T": "hau",
        "639-2/B": "hau",
        "language": "Hausa",
        "Native name": "Hausa, هَوُسَ"
    },
    {
        "639-1": "he",
        "639-2/T": "heb",
        "639-2/B": "heb",
        "language": "Hebrew (modern)",
        "Native name": "עברית"
    },
    {
        "639-1": "hi",
        "639-2/T": "hin",
        "639-2/B": "hin",
        "language": "Hindi",
        "Native name": "हिन्दी, हिंदी"
    },
    {
        "639-1": "ho",
        "639-2/T": "hmo",
        "639-2/B": "hmo",
        "language": "Hiri Motu",
        "Native name": "Hiri Motu"
    },
    {
        "639-1": "hr",
        "639-2/T": "hrv",
        "639-2/B": "hrv",
        "language": "Croatian",
        "Native name": "hrvatski jezik"
    },
    {
        "639-1": "ht",
        "639-2/T": "hat",
        "639-2/B": "hat",
        "language": "Haitian; Haitian Creole",
        "Native name": "Kreyòl ayisyen"
    },
    {
        "639-1": "hu",
        "639-2/T": "hun",
        "639-2/B": "hun",
        "language": "Hungarian",
        "Native name": "magyar"
    },
    {
        "639-1": "hy",
        "639-2/T": "hye",
        "639-2/B": "arm",
        "language": "Armenian",
        "Native name": "Հայերեն"
    },
    {
        "639-1": "hz",
        "639-2/T": "her",
        "639-2/B": "her",
        "language": "Herero",
        "Native name": "Otjiherero"
    },
    {
        "639-1": "ia",
        "639-2/T": "ina",
        "639-2/B": "ina",
        "language": "Interlingua",
        "Native name": "Interlingua"
    },
    {
        "639-1": "id",
        "639-2/T": "ind",
        "639-2/B": "ind",
        "language": "Indonesian",
        "Native name": "Bahasa Indonesia"
    },
    {
        "639-1": "ie",
        "639-2/T": "ile",
        "639-2/B": "ile",
        "language": "Interlingue",
        "Native name": "Originally called Occidental; then Interlingue after WWII"
    },
    {
        "639-1": "ig",
        "639-2/T": "ibo",
        "639-2/B": "ibo",
        "language": "Igbo",
        "Native name": "Asụsụ Igbo"
    },
    {
        "639-1": "ii",
        "639-2/T": "iii",
        "639-2/B": "iii",
        "language": "Nuosu",
        "Native name": "ꆈꌠ꒿ Nuosuhxop"
    },
    {
        "639-1": "ik",
        "639-2/T": "ipk",
        "639-2/B": "ipk",
        "language": "Inupiaq",
        "Native name": "Iñupiaq, Iñupiatun"
    },
    {
        "639-1": "io",
        "639-2/T": "ido",
        "639-2/B": "ido",
        "language": "Ido",
        "Native name": "Ido"
    },
    {
        "639-1": "is",
        "639-2/T": "isl",
        "639-2/B": "ice",
        "language": "Icelandic",
        "Native name": "Íslenska"
    },
    {
        "639-1": "it",
        "639-2/T": "ita",
        "639-2/B": "ita",
        "language": "Italian",
        "Native name": "italiano"
    },
    {
        "639-1": "iu",
        "639-2/T": "iku",
        "639-2/B": "iku",
        "language": "Inuktitut",
        "Native name": "ᐃᓄᒃᑎᑐᑦ"
    },
    {
        "639-1": "ja",
        "639-2/T": "jpn",
        "639-2/B": "jpn",
        "language": "Japanese",
        "Native name": "日本語 (にほんご)"
    },
    {
        "639-1": "jv",
        "639-2/T": "jav",
        "639-2/B": "jav",
        "language": "Javanese",
        "Native name": "basa Jawa"
    },
    {
        "639-1": "ka",
        "639-2/T": "kat",
        "639-2/B": "geo",
        "language": "Georgian",
        "Native name": "ქართული"
    },
    {
        "639-1": "kg",
        "639-2/T": "kon",
        "639-2/B": "kon",
        "language": "Kongo",
        "Native name": "KiKongo"
    },
    {
        "639-1": "ki",
        "639-2/T": "kik",
        "639-2/B": "kik",
        "language": "Kikuyu, Gikuyu",
        "Native name": "Gĩkũyũ"
    },
    {
        "639-1": "kj",
        "639-2/T": "kua",
        "639-2/B": "kua",
        "language": "Kwanyama, Kuanyama",
        "Native name": "Kuanyama"
    },
    {
        "639-1": "kk",
        "639-2/T": "kaz",
        "639-2/B": "kaz",
        "language": "Kazakh",
        "Native name": "қазақ тілі"
    },
    {
        "639-1": "kl",
        "639-2/T": "kal",
        "639-2/B": "kal",
        "language": "Kalaallisut, Greenlandic",
        "Native name": "kalaallisut, kalaallit oqaasii"
    },
    {
        "639-1": "km",
        "639-2/T": "khm",
        "639-2/B": "khm",
        "language": "Khmer",
        "Native name": "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ"
    },
    {
        "639-1": "kn",
        "639-2/T": "kan",
        "639-2/B": "kan",
        "language": "Kannada",
        "Native name": "ಕನ್ನಡ"
    },
    {
        "639-1": "ko",
        "639-2/T": "kor",
        "639-2/B": "kor",
        "language": "Korean",
        "Native name": "한국어 (韓國語), 조선어 (朝鮮語)"
    },
    {
        "639-1": "kr",
        "639-2/T": "kau",
        "639-2/B": "kau",
        "language": "Kanuri",
        "Native name": "Kanuri"
    },
    {
        "639-1": "ks",
        "639-2/T": "kas",
        "639-2/B": "kas",
        "language": "Kashmiri",
        "Native name": "कश्मीरी, كشميري‎"
    },
    {
        "639-1": "ku",
        "639-2/T": "kur",
        "639-2/B": "kur",
        "language": "Kurdish",
        "Native name": "Kurdî, كوردی‎"
    },
    {
        "639-1": "kv",
        "639-2/T": "kom",
        "639-2/B": "kom",
        "language": "Komi",
        "Native name": "коми кыв"
    },
    {
        "639-1": "kw",
        "639-2/T": "cor",
        "639-2/B": "cor",
        "language": "Cornish",
        "Native name": "Kernewek"
    },
    {
        "639-1": "ky",
        "639-2/T": "kir",
        "639-2/B": "kir",
        "language": "Kyrgyz",
        "Native name": "Кыргызча, Кыргыз тили"
    },
    {
        "639-1": "la",
        "639-2/T": "lat",
        "639-2/B": "lat",
        "language": "Latin",
        "Native name": "latine, lingua latina"
    },
    {
        "639-1": "lb",
        "639-2/T": "ltz",
        "639-2/B": "ltz",
        "language": "Luxembourgish, Letzeburgesch",
        "Native name": "Lëtzebuergesch"
    },
    {
        "639-1": "lg",
        "639-2/T": "lug",
        "639-2/B": "lug",
        "language": "Ganda",
        "Native name": "Luganda"
    },
    {
        "639-1": "li",
        "639-2/T": "lim",
        "639-2/B": "lim",
        "language": "Limburgish, Limburgan, Limburger",
        "Native name": "Limburgs"
    },
    {
        "639-1": "ln",
        "639-2/T": "lin",
        "639-2/B": "lin",
        "language": "Lingala",
        "Native name": "Lingála"
    },
    {
        "639-1": "lo",
        "639-2/T": "lao",
        "639-2/B": "lao",
        "language": "Lao",
        "Native name": "ພາສາລາວ"
    },
    {
        "639-1": "lt",
        "639-2/T": "lit",
        "639-2/B": "lit",
        "language": "Lithuanian",
        "Native name": "lietuvių kalba"
    },
    {
        "639-1": "lu",
        "639-2/T": "lub",
        "639-2/B": "lub",
        "language": "Luba-Katanga",
        "Native name": "Tshiluba"
    },
    {
        "639-1": "lv",
        "639-2/T": "lav",
        "639-2/B": "lav",
        "language": "Latvian",
        "Native name": "latviešu valoda"
    },
    {
        "639-1": "mg",
        "639-2/T": "mlg",
        "639-2/B": "mlg",
        "language": "Malagasy",
        "Native name": "fiteny malagasy"
    },
    {
        "639-1": "mh",
        "639-2/T": "mah",
        "639-2/B": "mah",
        "language": "Marshallese",
        "Native name": "Kajin M̧ajeļ"
    },
    {
        "639-1": "mi",
        "639-2/T": "mri",
        "639-2/B": "mao",
        "language": "Māori",
        "Native name": "te reo Māori"
    },
    {
        "639-1": "mk",
        "639-2/T": "mkd",
        "639-2/B": "mac",
        "language": "Macedonian",
        "Native name": "македонски јазик"
    },
    {
        "639-1": "ml",
        "639-2/T": "mal",
        "639-2/B": "mal",
        "language": "Malayalam",
        "Native name": "മലയാളം"
    },
    {
        "639-1": "mn",
        "639-2/T": "mon",
        "639-2/B": "mon",
        "language": "Mongolian",
        "Native name": "монгол"
    },
    {
        "639-1": "mr",
        "639-2/T": "mar",
        "639-2/B": "mar",
        "language": "Marathi (Marāṭhī)",
        "Native name": "मराठी"
    },
    {
        "639-1": "ms",
        "639-2/T": "msa",
        "639-2/B": "may",
        "language": "Malay",
        "Native name": "bahasa Melayu, بهاس ملايو‎"
    },
    {
        "639-1": "mt",
        "639-2/T": "mlt",
        "639-2/B": "mlt",
        "language": "Maltese",
        "Native name": "Malti"
    },
    {
        "639-1": "my",
        "639-2/T": "mya",
        "639-2/B": "bur",
        "language": "Burmese",
        "Native name": "ဗမာစာ"
    },
    {
        "639-1": "na",
        "639-2/T": "nau",
        "639-2/B": "nau",
        "language": "Nauru",
        "Native name": "Ekakairũ Naoero"
    },
    {
        "639-1": "nb",
        "639-2/T": "nob",
        "639-2/B": "nob",
        "language": "Norwegian Bokmål",
        "Native name": "Norsk bokmål"
    },
    {
        "639-1": "nd",
        "639-2/T": "nde",
        "639-2/B": "nde",
        "language": "North Ndebele",
        "Native name": "isiNdebele"
    },
    {
        "639-1": "ne",
        "639-2/T": "nep",
        "639-2/B": "nep",
        "language": "Nepali",
        "Native name": "नेपाली"
    },
    {
        "639-1": "ng",
        "639-2/T": "ndo",
        "639-2/B": "ndo",
        "language": "Ndonga",
        "Native name": "Owambo"
    },
    {
        "639-1": "nl",
        "639-2/T": "nld",
        "639-2/B": "dut",
        "language": "Dutch",
        "Native name": "Nederlands, Vlaams"
    },
    {
        "639-1": "nn",
        "639-2/T": "nno",
        "639-2/B": "nno",
        "language": "Norwegian Nynorsk",
        "Native name": "Norsk nynorsk"
    },
    {
        "639-1": "no",
        "639-2/T": "nor",
        "639-2/B": "nor",
        "language": "Norwegian",
        "Native name": "Norsk"
    },
    {
        "639-1": "nr",
        "639-2/T": "nbl",
        "639-2/B": "nbl",
        "language": "South Ndebele",
        "Native name": "isiNdebele"
    },
    {
        "639-1": "nv",
        "639-2/T": "nav",
        "639-2/B": "nav",
        "language": "Navajo, Navaho",
        "Native name": "Diné bizaad, Dinékʼehǰí"
    },
    {
        "639-1": "ny",
        "639-2/T": "nya",
        "639-2/B": "nya",
        "language": "Chichewa; Chewa; Nyanja",
        "Native name": "chiCheŵa, chinyanja"
    },
    {
        "639-1": "oc",
        "639-2/T": "oci",
        "639-2/B": "oci",
        "language": "Occitan",
        "Native name": "occitan, lenga d'òc"
    },
    {
        "639-1": "oj",
        "639-2/T": "oji",
        "639-2/B": "oji",
        "language": "Ojibwe, Ojibwa",
        "Native name": "ᐊᓂᔑᓈᐯᒧᐎᓐ"
    },
    {
        "639-1": "om",
        "639-2/T": "orm",
        "639-2/B": "orm",
        "language": "Oromo",
        "Native name": "Afaan Oromoo"
    },
    {
        "639-1": "or",
        "639-2/T": "ori",
        "639-2/B": "ori",
        "language": "Oriya",
        "Native name": "ଓଡ଼ିଆ"
    },
    {
        "639-1": "os",
        "639-2/T": "oss",
        "639-2/B": "oss",
        "language": "Ossetian, Ossetic",
        "Native name": "ирон æвзаг"
    },
    {
        "639-1": "pa",
        "639-2/T": "pan",
        "639-2/B": "pan",
        "language": "Panjabi, Punjabi",
        "Native name": "ਪੰਜਾਬੀ, پنجابی‎"
    },
    {
        "639-1": "pi",
        "639-2/T": "pli",
        "639-2/B": "pli",
        "language": "Pāli",
        "Native name": "पाऴि"
    },
    {
        "639-1": "pl",
        "639-2/T": "pol",
        "639-2/B": "pol",
        "language": "Polish",
        "Native name": "język polski, polszczyzna"
    },
    {
        "639-1": "ps",
        "639-2/T": "pus",
        "639-2/B": "pus",
        "language": "Pashto, Pushto",
        "Native name": "پښتو"
    },
    {
        "639-1": "pt",
        "639-2/T": "por",
        "639-2/B": "por",
        "language": "Portuguese",
        "Native name": "português"
    },
    {
        "639-1": "qu",
        "639-2/T": "que",
        "639-2/B": "que",
        "language": "Quechua",
        "Native name": "Runa Simi, Kichwa"
    },
    {
        "639-1": "rm",
        "639-2/T": "roh",
        "639-2/B": "roh",
        "language": "Romansh",
        "Native name": "rumantsch grischun"
    },
    {
        "639-1": "rn",
        "639-2/T": "run",
        "639-2/B": "run",
        "language": "Kirundi",
        "Native name": "Ikirundi"
    },
    {
        "639-1": "ro",
        "639-2/T": "ron",
        "639-2/B": "rum",
        "language": "Romanian",
        "Native name": "limba română"
    },
    {
        "639-1": "ru",
        "639-2/T": "rus",
        "639-2/B": "rus",
        "language": "Russian",
        "Native name": "русский язык"
    },
    {
        "639-1": "rw",
        "639-2/T": "kin",
        "639-2/B": "kin",
        "language": "Kinyarwanda",
        "Native name": "Ikinyarwanda"
    },
    {
        "639-1": "sa",
        "639-2/T": "san",
        "639-2/B": "san",
        "language": "Sanskrit (Saṁskṛta)",
        "Native name": "संस्कृतम्"
    },
    {
        "639-1": "sc",
        "639-2/T": "srd",
        "639-2/B": "srd",
        "language": "Sardinian",
        "Native name": "sardu"
    },
    {
        "639-1": "sd",
        "639-2/T": "snd",
        "639-2/B": "snd",
        "language": "Sindhi",
        "Native name": "सिन्धी, سنڌي، سندھی‎"
    },
    {
        "639-1": "se",
        "639-2/T": "sme",
        "639-2/B": "sme",
        "language": "Northern Sami",
        "Native name": "Davvisámegiella"
    },
    {
        "639-1": "sg",
        "639-2/T": "sag",
        "639-2/B": "sag",
        "language": "Sango",
        "Native name": "yângâ tî sängö"
    },
    {
        "639-1": "si",
        "639-2/T": "sin",
        "639-2/B": "sin",
        "language": "Sinhala, Sinhalese",
        "Native name": "සිංහල"
    },
    {
        "639-1": "sk",
        "639-2/T": "slk",
        "639-2/B": "slo",
        "language": "Slovak",
        "Native name": "slovenčina, slovenský jazyk"
    },
    {
        "639-1": "sl",
        "639-2/T": "slv",
        "639-2/B": "slv",
        "language": "Slovene",
        "Native name": "slovenski jezik, slovenščina"
    },
    {
        "639-1": "sm",
        "639-2/T": "smo",
        "639-2/B": "smo",
        "language": "Samoan",
        "Native name": "gagana fa'a Samoa"
    },
    {
        "639-1": "sn",
        "639-2/T": "sna",
        "639-2/B": "sna",
        "language": "Shona",
        "Native name": "chiShona"
    },
    {
        "639-1": "so",
        "639-2/T": "som",
        "639-2/B": "som",
        "language": "Somali",
        "Native name": "Soomaaliga, af Soomaali"
    },
    {
        "639-1": "sq",
        "639-2/T": "sqi",
        "639-2/B": "alb",
        "language": "Albanian",
        "Native name": "gjuha shqipe"
    },
    {
        "639-1": "sr",
        "639-2/T": "srp",
        "639-2/B": "srp",
        "language": "Serbian",
        "Native name": "српски језик"
    },
    {
        "639-1": "ss",
        "639-2/T": "ssw",
        "639-2/B": "ssw",
        "language": "Swati",
        "Native name": "SiSwati"
    },
    {
        "639-1": "st",
        "639-2/T": "sot",
        "639-2/B": "sot",
        "language": "Southern Sotho",
        "Native name": "Sesotho"
    },
    {
        "639-1": "su",
        "639-2/T": "sun",
        "639-2/B": "sun",
        "language": "Sundanese",
        "Native name": "Basa Sunda"
    },
    {
        "639-1": "sv",
        "639-2/T": "swe",
        "639-2/B": "swe",
        "language": "Swedish",
        "Native name": "Svenska"
    },
    {
        "639-1": "sw",
        "639-2/T": "swa",
        "639-2/B": "swa",
        "language": "Swahili",
        "Native name": "Kiswahili"
    },
    {
        "639-1": "ta",
        "639-2/T": "tam",
        "639-2/B": "tam",
        "language": "Tamil",
        "Native name": "தமிழ்"
    },
    {
        "639-1": "te",
        "639-2/T": "tel",
        "639-2/B": "tel",
        "language": "Telugu",
        "Native name": "తెలుగు"
    },
    {
        "639-1": "tg",
        "639-2/T": "tgk",
        "639-2/B": "tgk",
        "language": "Tajik",
        "Native name": "тоҷикӣ, toğikī, تاجیکی‎"
    },
    {
        "639-1": "th",
        "639-2/T": "tha",
        "639-2/B": "tha",
        "language": "Thai",
        "Native name": "ไทย"
    },
    {
        "639-1": "ti",
        "639-2/T": "tir",
        "639-2/B": "tir",
        "language": "Tigrinya",
        "Native name": "ትግርኛ"
    },
    {
        "639-1": "tk",
        "639-2/T": "tuk",
        "639-2/B": "tuk",
        "language": "Turkmen",
        "Native name": "Türkmen, Түркмен"
    },
    {
        "639-1": "tl",
        "639-2/T": "tgl",
        "639-2/B": "tgl",
        "language": "Tagalog",
        "Native name": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"
    },
    {
        "639-1": "tn",
        "639-2/T": "tsn",
        "639-2/B": "tsn",
        "language": "Tswana",
        "Native name": "Setswana"
    },
    {
        "639-1": "to",
        "639-2/T": "ton",
        "639-2/B": "ton",
        "language": "Tonga (Tonga Islands)",
        "Native name": "faka Tonga"
    },
    {
        "639-1": "tr",
        "639-2/T": "tur",
        "639-2/B": "tur",
        "language": "Turkish",
        "Native name": "Türkçe"
    },
    {
        "639-1": "ts",
        "639-2/T": "tso",
        "639-2/B": "tso",
        "language": "Tsonga",
        "Native name": "Xitsonga"
    },
    {
        "639-1": "tt",
        "639-2/T": "tat",
        "639-2/B": "tat",
        "language": "Tatar",
        "Native name": "татар теле, tatar tele"
    },
    {
        "639-1": "tw",
        "639-2/T": "twi",
        "639-2/B": "twi",
        "language": "Twi",
        "Native name": "Twi"
    },
    {
        "639-1": "ty",
        "639-2/T": "tah",
        "639-2/B": "tah",
        "language": "Tahitian",
        "Native name": "Reo Tahiti"
    },
    {
        "639-1": "ug",
        "639-2/T": "uig",
        "639-2/B": "uig",
        "language": "Uyghur, Uighur",
        "Native name": "Uyƣurqə, ئۇيغۇرچە‎"
    },
    {
        "639-1": "uk",
        "639-2/T": "ukr",
        "639-2/B": "ukr",
        "language": "Ukrainian",
        "Native name": "українська мова"
    },
    {
        "639-1": "ur",
        "639-2/T": "urd",
        "639-2/B": "urd",
        "language": "Urdu",
        "Native name": "اردو"
    },
    {
        "639-1": "uz",
        "639-2/T": "uzb",
        "639-2/B": "uzb",
        "language": "Uzbek",
        "Native name": "O‘zbek, Ўзбек, أۇزبېك‎"
    },
    {
        "639-1": "ve",
        "639-2/T": "ven",
        "639-2/B": "ven",
        "language": "Venda",
        "Native name": "Tshivenḓa"
    },
    {
        "639-1": "vi",
        "639-2/T": "vie",
        "639-2/B": "vie",
        "language": "Vietnamese",
        "Native name": "Tiếng Việt"
    },
    {
        "639-1": "vo",
        "639-2/T": "vol",
        "639-2/B": "vol",
        "language": "Volapük",
        "Native name": "Volapük"
    },
    {
        "639-1": "wa",
        "639-2/T": "wln",
        "639-2/B": "wln",
        "language": "Walloon",
        "Native name": "walon"
    },
    {
        "639-1": "wo",
        "639-2/T": "wol",
        "639-2/B": "wol",
        "language": "Wolof",
        "Native name": "Wollof"
    },
    {
        "639-1": "xh",
        "639-2/T": "xho",
        "639-2/B": "xho",
        "language": "Xhosa",
        "Native name": "isiXhosa"
    },
    {
        "639-1": "yi",
        "639-2/T": "yid",
        "639-2/B": "yid",
        "language": "Yiddish",
        "Native name": "ייִדיש"
    },
    {
        "639-1": "yo",
        "639-2/T": "yor",
        "639-2/B": "yor",
        "language": "Yoruba",
        "Native name": "Yorùbá"
    },
    {
        "639-1": "za",
        "639-2/T": "zha",
        "639-2/B": "zha",
        "language": "Zhuang, Chuang",
        "Native name": "Saɯ cueŋƅ, Saw cuengh"
    },
    {
        "639-1": "zh",
        "639-2/T": "zho",
        "639-2/B": "chi",
        "language": "Chinese",
        "Native name": "中文 (Zhōngwén), 汉语, 漢語"
    },
    {
        "639-1": "zu",
        "639-2/T": "zul",
        "639-2/B": "zul",
        "language": "Zulu",
        "Native name": "isiZulu"
    }
];
export default languages;