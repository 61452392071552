import React from "react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Product from "./Product";
import './Category.css';
import { useSelector } from "react-redux";

const Category = ({ category }) => {
  const search_filters = useSelector((state) => state.search_filters);
  let numberOfShownItemsInThisCategory = category.items.reduce(
    (a, b) => +a + +b.show,
    0
  );
  let classn = search_filters.length
    ? "ProductList"
    : numberOfShownItemsInThisCategory > 4
    ? "categoryCSS"
    : numberOfShownItemsInThisCategory < 3
    ? "categoryCSS5"
    : "categoryCSS4";

  return (
    <div className="wrapperCategory">
      <div className="CategoryDescription">
        {category.name}{" "}
      </div>
      <div className={classn}>
              {category.items
                  .filter((item) => item.show === true)
          .map((product) => <Product product={{ ...product ,currency:category.currency}} key={product.id}></Product>
                  )}
      </div>
    </div>
  );

};

export default Category;
