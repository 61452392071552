import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import Axios from "axios";
import { useDispatch } from "react-redux";
import './PhotoMenuCreate.css'
import { max } from "lodash";

let fixRotation = require('fix-image-rotation')


const PhotoMenuCreate = () => {

  
    const dispatch = useDispatch();
  const [showNamePlaceDialogue, setshowNamePlaceDialogue] = useState(false);
    const [venueName, setVenueName] = useState('');
    const [venuePlace, setVenuePlace] = useState('');
  const [selectedImage, setselectedImage] = useState(null);
    const [photos, setphotos] = useState([]);

    const uploadOnePhoto = (photo) => {
        console.log('uploading photo ...', photo.imageName);
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("token"),
            },
        };

        let fd = new FormData();
        fd.append("name", photo.imageName);
        fd.append("file", photo.imageData);
        // if (edit) {
        Axios
            .post("https://admin.e-menu.co.edit8.com:8000/api/upload_menu_photo", fd, config)
            .then((response) => {
                console.log("photo posted successfully: ", response);
                // alert('photo posted successfully:');
                // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
            })
            .catch((err) => {
                 alert(err);
            });
}

    const uploadImagesAndCreateMenu = (dispatch) => {
        let userId = localStorage.getItem('userId');
        let photosNames = [];
        let photosNames2 = [];
        let copyOfPhotos = [...photos];
        for (let photo of copyOfPhotos) {
           
            delete photo.imageData;
            // photosNames.push(photo.imageName);
        }

        let myPromise = () => {
            // return new Promise((resolve, reject) => {
            //     for (let [index, photo] of photos.entries()) {
            //         let config = {
            //             headers: {
            //                 "Content-Type": "multipart/form-data",
            //                 Authorization: localStorage.getItem("token"),
            //             },
            //         };
        
            //         let fd = new FormData();
            //         fd.append("name", photo.imageName);
            //         fd.append("file", photo.imageData);
            //         // if (edit) {
            //         Axios
            //             .post("https://admin.e-menu.co.edit8.com:8000/api/upload_menu_photo", fd, config)
            //             .then((response) => {
            //                 console.log("photo posted successfully: ", response);
            //                 photosNames2.push(photo.imageName);
            //                 if (photosNames2.length === photos.length ) {
            //                     resolve("Success!");
            //                 }
            //                 // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
            //             })
            //             .catch((err) => {
            //                 console.error("photo post failed: ", err);
            //                 reject('error');
            //             });
            //         // }
            //     }

            // });
        }

        let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
        };

        // myPromise().then(res => {
            Axios
                .post(
                    "https://admin.e-menu.co.edit8.com:8000/api/upload_photo_menu",
                    { venueName, venuePlace, photos:copyOfPhotos ,type:"photo",short_code:venueName+'_'+venuePlace,deleted:false},
                    config
              
                )
                .then((res) => {
                    dispatch({ type: "PHOTO_MENU_QR_READY" });
                })
                .catch((err) => {
                    console.log(err);
                });
        // }).catch(err => console.log(err));


       

    }
    const handleImage7 = (e) => {
        var reader = new FileReader();
        var selectedFile =  e.target.files[0];
        reader.onload = function (event) {
            var img = new Image();
            var dataurl = null;
            img.onload = function (imageEvent) {
                if (img.width > img.height)
                {
                var canvas = document.createElement('canvas');
                canvas.height = img.width;
                canvas.width = img.height;
                var ctx = canvas.getContext("2d");
               
                ctx.translate(canvas.width / 2, canvas.height / 2);

                // roate the canvas by +90% (==Math.PI/2)
                ctx.rotate(Math.PI / 2);
    
                // draw the signature
                // since images draw from top-left offset the draw by 1/2 width & height
                ctx.drawImage(img, -img.width / 2, -img.height / 2);
    
                // un-rotate the canvas by -90% (== -Math.PI/2)
                ctx.rotate(-Math.PI / 2);
    
                // un-translate the canvas back to origin==top-left canvas
                ctx.translate(-canvas.width / 2, -canvas.height / 2);

                dataurl = canvas.toDataURL("image/jpg");
             
                    
                }
                else
                    
                {
                    dataurl = img.src;
                    }
                


                var h = img.naturalHeight;
                var w = img.naturalWidth;
                let isLandscape = w > h ? true : false;
                 
   
                setselectedImage(dataurl);
                let time = (new Date().getTime());
                console.log('we are gopoing to upload this...')
                setphotos([...photos, { imageName: localStorage.getItem('userId') + '_' + time + ".jpg", imageData: dataurl, landscape: isLandscape ,height:h,width:w}]);
                //   window.alert(localStorage.getItem('userId') + '_' + time + ".jpg");
                uploadOnePhoto({ imageName: localStorage.getItem('userId') + '_' + time + ".jpg", imageData:dataurl, landscape: isLandscape });
   


            }
            img.src = event.target.result;
        }
        reader.readAsDataURL(selectedFile);
}

 const handleImage6 = (e) => {

        var reader = new FileReader();
        var selectedFile =  e.target.files[0];
     reader.onload = function (event) {
         var dataurl = selectedFile;
         
         var img = new Image();
         img.onload = function (imageEvent) {
             var h = img.naturalHeight;
             var w = img.naturalWidth;
             let isLandscape = w > h ? true : false;
              

             setselectedImage(dataurl);
             let time = (new Date().getTime());
             console.log('we are gopoing to upload this...')
             setphotos([...photos, { imageName: localStorage.getItem('userId') + '_' + time + ".jpg", imageData: img.src, landscape: isLandscape ,height:h,width:w}]);
             //   window.alert(localStorage.getItem('userId') + '_' + time + ".jpg");
             uploadOnePhoto({ imageName: localStorage.getItem('userId') + '_' + time + ".jpg", imageData: img.src, landscape: isLandscape });


         }
         img.src = event.target.result; 
     }
     reader.readAsDataURL(selectedFile);
    }

//   const handleLoadImage = (e) => {
//     var file = e.target.files[0];
//     var reader = new FileReader();
//     reader.onload = (e) => {
//       var img = document.createElement("img");
//       img.onload = () => {
//         var canvas = document.createElement("canvas");
       
//           var ctx = null;
//         // var MAX_WIDTH = 1080;
//         // var MAX_HEIGHT = 1920;
//         var width = img.width;
//         var height = img.height;
//           if (width > height) {
//           canvas =  rotateImg(canvas, img);
              
//           }
//           else
//           {
//             canvas =  processImgWithoutRotation(canvas, img);
//               }
//         // if (width > height) {
//         //   if (width > MAX_WIDTH) {
//         //     height *= MAX_WIDTH / width;
//         //     width = MAX_WIDTH;
//         //   }
//         // } else {
//         //   if (height > MAX_HEIGHT) {
//         //     width *= MAX_HEIGHT / height;
//         //     height = MAX_HEIGHT;
//         //   }
//         // }
//         // canvas.width = 1080;
//         // canvas.height = 1980;
          
       
//             //   ctx.drawImage(img, 0, 0,1080,1920);
         
//         var dataurl = canvas.toDataURL("image/jpg");
//         console.log(dataurl);
//           setselectedImage(dataurl);
//           let time = (new Date().getTime());
//           setphotos([...photos, { imageName: localStorage.getItem('userId') + '_' + time + ".jpg", imageData: dataurl }]);
//         //   window.alert(localStorage.getItem('userId') + '_' + time + ".jpg");
//           uploadOnePhoto({ imageName: localStorage.getItem('userId') + '_' + time + ".jpg", imageData: dataurl });
           
//       };
//       img.src = e.target.result;
//     };
//     reader.readAsDataURL(file);
//   };
  return (
    <>
      <div
        style={{
          display: "flex",
          position: "sticky",
          width: "100%",
          minHeight: "100vh",
          right: 0,
          top: 0,

          left: 0,
          bottom: 0,
          backgroundColor: "white",
          alignItems: "center",
          alignContent: "center",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "scroll",
        }}
      >
              Let's create menu using menu photos / your camera
        {photos.map((photo) => {
            
            if (photo.landscape)
            {
                return <img  key = {photo.imageName} style =  {{ rotate:90, width:"100vw"}} src={photo.imageData}></img>
                              
            }
            else
         return  <img key = {photo.imageName} style={{  width: "100vw" }} src={photo.imageData}></img>
        })}
      </div>
      <Button
        component="label"
      >
        {!photos.length
          ? "Add photo - Use Camera - Gallery Photo"
          : "Add another?"}

        <input
          type="file"
          id="myfile"
          name="myfile"
          onChange={(e) => {
            //   handleLoadImage(e);
            handleImage7(e);
          }}
          style={{ display: "none" }}
        />
      </Button>
      <br></br>
      {photos.length ? (
        <Button
          style={{ position: "sticky", bottom: 0, zIndex: 1000000 }}
          variant="contained"
          component="label"
          onClickCapture={() => setshowNamePlaceDialogue(true)}
        >
          Finished? Get QR Code NOW!
        </Button>
      ) : null}
      {showNamePlaceDialogue ? (
        <div
                  style={{
              zIndex:1000000000,
            display: "flex",
            position: "sticky",
            width: "100%",
            minHeight: "100vh",
            right: 0,
            top: 0,

            left: 0,
            bottom: 0,
            backgroundColor: "white",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "column",
            justifyContent: "center",
            overflow: "scroll",
          }}
        >
              <div> Please tell us your venue name and place to get yoour qr code</div>   
                  <TextField onChange = {(e)=>setVenueName(e.target.value)}></TextField>      
                  <TextField onChange = {(e)=>setVenuePlace(e.target.value)}></TextField>   
                  <Button onClick = {()=>uploadImagesAndCreateMenu(dispatch)}>Upload Content and get my QR Code NOW!</Button>
        </div>
      ) : null}
    </>
  );
};

export default PhotoMenuCreate;
