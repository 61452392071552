import React ,{Suspense, lazy}from "react";
import ReactDOM from "react-dom";
import demo_autocompletes from './demo_autocompletes'
import "./index.css";
import App from "./App";
import {
  BrowserRouter,
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import { Provider, useStore } from "react-redux";
import { createStore ,applyMiddleware} from "redux";
// import { isMobile } from "react-device-detect";
// import demoData from "./demoData.js";
// import demoData2 from "./demoData2.js";
import thunk from 'redux-thunk';
import SuccessStripePage from "./SuccessStripePage";

import InfoModal from "./InfoModal";
import MyMenus from "./MyMenus";
import MenuEditCreate from "./MenuEditCreate";
import autocompletes from "./demo_autocompletes";
import PdfMenuCreate from "./PdfMenuCreate";
import PhotoMenuCreate from "./PhotoMenuCreate";
import countriesJson from "./countriesJson";
import currencies from "./Currencies";

const LoginPage = lazy(() => import('./LoginPage'))



// import BusinessLogin from "./BusinessLogin";
// import { act } from "react-dom/test-utils";

// import Translate from 'google-translate-open-api';
// const Translate = require('google-translate-api');

let translate = (sentence, state) => {
  return state.translation[state.default_language]
    ? state.translation[state.default_language][sentence]
      ? state.translation[state.default_language][sentence]
      : sentence
    : null;
};

// for (let category of demoData.categories) {
//   category.show = true;
//   category.strongMatch = true;
//   for (let item of category.items) {
//     item.show = true;
//   }
// }
const menuDataInit = {
  menuTables: [],
  autocompletes: [{ name: 'test', description: "testdescription" }],
  knownUsers: [],
  currentMenus: [],
  user: {},
  qrCode: false,
  address: "",
  autoTranslation: false,
  menuCity: '',
  menuCurrency: 'EUR - Euro',
  menuVenueName: '',
  menuCountry: "England",
  originalLanguage: "English",
  locationLat: "",
  locationLng: "",
  locationRadius: "",
  imageLogoBg: "",
  imageLogo: "",
  workingHours: "",
  version: "",
  short_code: "",
  website: "",
  categories: [
    // { id:1,
    //   name: "coffee",
    //   items: [{ name: "Espresso" ,id:2323,show:true,price:"30"}, { name: "Machiatto" ,id:3452345,show:true,price:"30"}],
    // },
    // {
    //   id:2,
    //   name: "Tea",
    //   items: [{ name: "Green tea",id:234987 ,show:true,price:"30"}, { name: "Strawberry tea",id:1456454,show:true,price:"30" }],
    // },
  ]
}


const initialState = {


  locale:{country:'en',language:'en',currency:'eur'},
  defaultMenu:{},
  // ...demoData2,
  categories: [],
  goBackButton: true,
  productInfoModal:{show:false,productData:{}},
  menuData: {
    menuTables:[],
    autocompletes:[{ name: 'test',description:"testdescription" }],
    knownUsers:[],
    currentMenus:[],
    user: {},
    qrCode:false,
    address:"",
    autoTranslation:false,
    menuCity: '',
    menuCurrency:'EUR - Euro',
    menuVenueName:'',
    menuCountry:"England",
    originalLanguage: "English",
    locationLat: "",
    locationLng: "",
    locationRadius: "",
    imageLogoBg: "",
    imageLogo:"",
    workingHours:"",
    version: "",
    short_code:"",
    website:"",
    categories: [
      // { id:1,
      //   name: "coffee",
      //   items: [{ name: "Espresso" ,id:2323,show:true,price:"30"}, { name: "Machiatto" ,id:3452345,show:true,price:"30"}],
      // },
      // {
      //   id:2,
      //   name: "Tea",
      //   items: [{ name: "Green tea",id:234987 ,show:true,price:"30"}, { name: "Strawberry tea",id:1456454,show:true,price:"30" }],
      // },
    ],
  },
  shoppingCart: [],
  search_filters: [],
  scale: 1,
  translation: {
    mk: { example: "пример", артикли: "", item: "артикл", ORDER: "Нарачај" },
  },
  default_currency: "eur",
  default_language: "en",
  translate: translate,
};

// async function TraslateThis(word,srcLang,destLang) {
//   Translate(word, {from: srcLang, to: destLang}).then(res => {
//     console.log(res.text);
//     //=> Ik spreek Nederlands!
//     console.log(res.from.text.autoCorrected);
//     //=> true
//     console.log(res.from.text.value);
//     //=> I [speak] Dutch!
//     console.log(res.from.text.didYouMean);
//     return res.from.text.value;
//     //=> false
// }).catch(err => {
//     console.error(err);
// });
// }

// const changeLanguage =(lang,state) => {

//   for (let category of state.categories) {

//     category.name = TraslateThis(category.name, state.default_language, 'fr')[0]
//     for (let item of category.items)
//     {
//       item.name = TraslateThis(item.name, state.default_language, 'fr')[0]
//       item.description =  TraslateThis(item.description, state.default_language, 'fr')[0]
//       }
//   }
//   return state;
// }
const changeValuePosition = (arr, init, target) => { [arr[init], arr[target]] = [arr[target], arr[init]]; return arr };
function reducer(state = initialState, action) {
  console.log("reducer", state, action);

  switch (action.type) {
  


    case "SET_COORDINATES":
      state.menuData = { ...state.menuData, locationLat: action.payload.latitude, locationLng: action.payload.longitude }
      break;
    case "MODAL_PRODUCT_INFO":
      state = { ...state, productInfoModal: action.payload };
      break;
    case "ADD_KNOWN_USER":
      state.menuData = {
        ...state.menuData, knownUsers: [...state.menuData.knownUsers, action.payload.data
        ]
      }
      break;
    case "GOBACK_STATUS":
      state.goBackButton = action.payload;
      break;
    
    
    
    case "SAVE_EDITED_CATEGORY_NAME":
      let menuDatacategories2 = [...state.menuData.categories];
      menuDatacategories2.forEach((cat,index) => { 
        if (cat.id === action.payload.id)
        {
          menuDatacategories2[index] = action.payload;
          }
      });
      let menuData2 = { ...state.menuData, categories: menuDatacategories2 };
      state.menuData = menuData2;;
      
      break;
    
    
    case "CREATE_NEW_TABLE":
      state.menuData = { ...state.menuData, menuTables: [...state.menuData.menuTables, { tableCode: action.payload }]};
      
      
      break;
    case 'BATCH_CREATE_NEW_TABLES':
      // tableCodeBatch, batchNumber
      for(let i = 0 ; i< action.payload.batchNumber;i++)
        state.menuData = { ...state.menuData, menuTables: [...state.menuData.menuTables, { tableCode: action.payload.tableCodeBatch+(i+1) }]};

        break;
    
    case "ADD_PRODUCT_TO_MENU":

      if (action.payload.product.name.length < 2) break;
      let tempProd = action.payload.product;
      if (!tempProd.image)
        tempProd.image = "https://e-menupull.b-cdn.net/placeholder.jpg"
      let copyOFcats = [...state.menuData.categories];
      copyOFcats.forEach(cat => {
        if (cat.name === action.payload.targetCat.name) cat.items.push(tempProd);
      });
      
      state.menuData = { ...state.menuData, categories: copyOFcats };
      break;
    case "UPDATE_PRODUCT_AFTER_EDIT":
      if (action.payload.product.name.length < 2) break;
      let tempProd2 = action.payload.product;
      if (!tempProd2.image)
        tempProd2.image = "https://e-menupull.b-cdn.net/placeholder.jpg"
      let copyOFcats2 = [...state.menuData.categories];
      copyOFcats2.forEach((cat, catIndex) => {
        if (cat.name === action.payload.targetCat.name) {
          cat.items.forEach((item, index) => {
            if (item.id === action.payload.product.id) {
              let newProduct = tempProd2;
              newProduct.id = (new Date()).getTime();
              copyOFcats2[catIndex].items[index] = newProduct;
            }
          })
         
        }
      });
      state.menuData = { ...state.menuData, categories: copyOFcats2 };
      break;
    case "PREPARE_MENU_START":

      
    case "SET_MENUS_FOR_USER":
      state.menuData.user = { ...state.menuData.user, currentMenus: action.payload };
      break;
      
      
      break;
    case "QR_READY":
      state.menuData = { ...state.menuData, qrCode: true }
      break;
    case "ADD_PRODUCT_TO_SHOPPING_CART":
      // state.shoppingCart = [
      //   ...state.shoppingCart,
      //   JSON.parse(
      //     JSON.stringify({ ...action.payload, timestamp_added: Date.now() })
      //   ),
      // ];
      break;
    case "LOAD_PREVIOUS_MENU":
      let checktableArray = action.payload.menuTables;
      if (!checktableArray)
        action.payload.menuTables = [];
      
      state.menuData = { ...state.menuData, ...action.payload }
      break;
    case "SET_USER_DETAILS":
      // if (action.payload.userID === state.menuData.userId) {
      
      var autoCompletes = [...state.menuData.autocompletes];
      if (action.payload.currentMenus)
      action.payload.currentMenus.map(menu => {
        if (menu.categories) {
          menu.categories.map(category => {
            category.items.map(item => {
              autoCompletes.push(item);
            })
          })
        }
      })


      autoCompletes = demo_autocompletes;

      if (autoCompletes.length > 0)
      {
       
        state.menuData = { ...state.menuData, user: { ...state.menuData.user, ...action.payload }, autocompletes: autoCompletes };

      }
      else
        state.menuData = {
          ...state.menuData, user: { ...state.menuData.user, ...action.payload }
        }
      // }

      console.log('AUTOCOMPLETES:',JSON.stringify(autoCompletes, null, 2)); 
    
      console.log("current menus:", state.menuData.user.currentMenus);
      break;
    case "CHANGE_CITY_NAME":
      state.menuData = { ...state.menuData, menuCity: action.payload.replace(/[^A-Z0-9]+/ig, "_") };
      break;
    case "CHANGE_VENUE_NAME":
      state.menuData = { ...state.menuData, menuVenueName: action.payload.replace(/[^A-Z0-9]+/ig, "_") };
      break;
   
    case "CHANGE_MENU_COUNTRY":
      state.menuData = { ...state.menuData, menuCountry: action.payload }
      break;
    case "CHANGE_MENU_CURRENCY":
      state.menuData = { ...state.menuData, menuCurrency: action.payload }
      break;
      case "CHANGE_MENU_LANGUAGE":
        state.menuData = { ...state.menuData, language: action.payload }
        break;
    case "CHANGE_MENU_ORIGINAL_LANGUAGE":
      state.menuData = { ...state.menuData, originalLanguage: action.payload }
      break;
    case "ADD_CATEGORY":
     
      let menuDatacategories = [...state.menuData.categories, { id: (new Date()).getTime(), show: true, name: action.payload, items: [] }]
      let menuData = { ...state.menuData, categories: menuDatacategories };
      state.menuData = menuData;;
      break;
    case "ADD_CATEGORY_FROM_ZIP":
     
        let menuDatacategories6 = [...state.menuData.categories, action.payload]
        let menuData6 = { ...state.menuData, categories: menuDatacategories6 };
        state.menuData = menuData6;;
        break;
    case "MOVE_CATEGORY_UP":
      let arr = changeValuePosition(state.menuData.categories, action.payload, Math.max(0, action.payload - 1));
      state.menuData = { ...state.menuData, categories: arr }
     
      break;
    case "MOVE_CATEGORY_DOWN":
  
      state.menuData = { ...state.menuData, categories: changeValuePosition(state.menuData.categories, action.payload, Math.min(state.menuData.categories.length - 1, action.payload + 1)) }
     
      break;
    case "DELETE_CATEGORY":
       

      state.menuData = { ...state.menuData, categories: state.menuData.categories.filter(category => category.name !== action.payload) };
      break;
    case "DELETE_PRODUCT":
      let cats = [...state.menuData.categories];
      let category = state.menuData.categories.filter((category, index) => index === action.payload.categoryIndex)[0];
      category.items = category.items.filter((product, index) => index !== action.payload.productIndex);
      cats[action.payload.categoryIndex] = category;
      state.menuData = { ...state.menuData, categories: cats }
     
      break;
    case "MOVE_PRODUCT_DOWN":
      let cats2 = [...state.menuData.categories];
      let category2 = state.menuData.categories.filter((category, index) => index === action.payload.categoryIndex)[0];
      category2.items = changeValuePosition(category2.items, action.payload.productIndex, Math.min(category2.items.length - 1, action.payload.productIndex + 1))
      cats2[action.payload.categoryIndex] = category2;
      state.menuData = { ...state.menuData, categories: cats2 }
         
      break;
 
    case "MOVE_PRODUCT_TO_CATEGORY":
      let cats4 = [...state.menuData.categories];

      let pprod = state.menuData.categories[action.payload.sourceCategoryIndex].items[action.payload.sourceProductIndex];


      for (let i = 0; i < cats4.length; i++) {
        
        if (cats4[i].name === action.payload.targetCat) {
          cats4[i].items.push(pprod);
          break;
        }
      
      }
      const removeByIndex = (list, index) =>
        [
          ...list.slice(0, index),
          ...list.slice(index + 1)
        ];
      for (let i = 0; i < cats4.length; i++) {
      
        if (i === action.payload.sourceCategoryIndex) {
          cats4[i].items = removeByIndex(cats4[i].items, action.payload.sourceProductIndex)
         
          break;
        }
      
      }
     
     


      state.menuData = { ...state.menuData, categories: cats4 }
       
      console.log(action.payload);
         
      break;
    case "MOVE_PRODUCT_UP":
  
      let cats3 = [...state.menuData.categories];
      let category3 = state.menuData.categories.filter((category, index) => index === action.payload.categoryIndex)[0];
      category3.items = changeValuePosition(category3.items, action.payload.productIndex, Math.max(0, action.payload.productIndex - 1))
      cats3[action.payload.categoryIndex] = category3;
      state.menuData = { ...state.menuData, categories: cats3 }
         
      break;
    case "REMOVE_PRODUCT_FROM_SHOPPING_CART":
      state.shoppingCart = [
        ...state.shoppingCart.filter(
          (product) =>
            product.timestamp_added !== action.payload.timestamp_added
        ),
      ];

      break;

    case "SET_LOCALE_DETAILS":
      localStorage.removeItem('locale');
      state.locale = action.payload;
      var locale = JSON.parse(localStorage.getItem('locale'));
      if (locale) {
        state.menuData.menuCountry = countriesJson[locale.country.toUpperCase()];
        state.menuData.language = locale.language;
        state.menuData.menuCity = locale.city;
        state.menuData.menuCurrency = currencies.filter(curr => curr.code.toLowerCase() === locale.currency)[0].name;
        localStorage.setItem('locale', JSON.stringify(action.payload));
      }
      else
      {
        locale = action.payload;
        if (locale.country) {
          // localStorage.setItem('locale', action.payload);
          state.menuData.menuCountry = countriesJson[locale.country.toUpperCase()];
          state.menuData.language = locale.language;
          state.menuData.menuCity = locale.city;
          state.menuData.menuCurrency = currencies.filter(curr => curr.code.toLowerCase() === locale.currency)[0].name;
          localStorage.setItem('locale', JSON.stringify(action.payload));
        }
        }
      break;
    
    case "RESET_MENU_DATA":

      state.menuData = menuDataInit;
      var locale = JSON.parse(localStorage.getItem('locale'));
      if (locale.country) {
        state.menuData.menuCountry = countriesJson[locale.country.toUpperCase()];
        state.menuData.menuCity = locale.city.toLowerCase();
        state.menuData.language = locale.language;
        state.menuData.menuCurrency = currencies.filter(curr => curr.code.toLowerCase() === locale.currency)[0].name;
      }
      
      
      break;
    case "SEARCH_FOR_PRODUCTS":
      state.shoppingCart = [
        ...state.shoppingCart,
        JSON.parse(
          JSON.stringify({ ...action.payload, timestamp_added: Date.now() })
        ),
      ];

      break;

    case "SEARCH":
      if (action.payload.length) {
        for (let category of state.categories) {
          category.show = false;
          category.strongMatch = false;
          for (let item of category.items) {
            item.show = false;
          }
        }
      } else {
        for (let category of state.categories) {
          category.show = true;
          category.strongMatch = false;
          for (let item of category.items) {
            item.show = true;
          }
        }
        state.search_filters = [];
        break;
      }
      state.search_filters = action.payload.split(/(\s+)/);
      state.search_filters = state.search_filters.filter((filter) => {
        return filter !== "" && filter !== " " && filter !== "  ";
      });
      state.search_filters.forEach(
        (filter, index) => (state.search_filters[index] = filter.toUpperCase())
      );
      break;
    case "CHANGE_LANG":
      // state = changeLanguage('fr', state);
      break;
    case "NEW_DATA_FROM_SERVER":
      let menusWithAllAvailableLanguages = action.payload.menus;
      let phoneLanguage = action.payload.phoneLanguage;
      let defaultMenu = null;
      for (let oneLanguage of menusWithAllAvailableLanguages) {
        defaultMenu = oneLanguage;
        for (let category of defaultMenu.categories) {
          category.show = true;
          category.strongMatch = true;
          for (let item of category.items) {
            item.show = true;
          }
        }
      }
      for (let oneLanguage of menusWithAllAvailableLanguages) {
       
        for (let category of oneLanguage.categories) {
          category.show = true;
          category.strongMatch = true;
          for (let item of category.items) {
            item.show = true;
          }
        }
        if (oneLanguage.language === phoneLanguage)
        defaultMenu = oneLanguage;
      
      }
      


      state = {
        ...state,  ...defaultMenu , menusWithAllAvailableLanguages: menusWithAllAvailableLanguages
      }
      break;
    default:
      break;
  }
    // case "NEW_DATA_FROM_SERVER_OLD":
    //   let newState = action.payload;
    //   for (let category of newState.categories) {
    //     category.show = true;
    //     category.strongMatch = true;
    //     for (let item of category.items) {
    //       item.show = true;
    //     }
    //   }
    //   state = {
    //     ...state, ...newState, menuData: { menuCurrency:newState.menuCurrency?newState.menuCurrency:"MKD - Denar" }
    //   }
    //   break;
    // default:
    //   break;
  
  
  if (state.search_filters.length) {
    let categories = [...state.categories];
    for (let category of categories) {
      for (let filter of state.search_filters) {
        if (category.name.toUpperCase().includes(filter)) {
          category.show = true;
          category.strongMatch = true;
          for (let item of category.items) {
            item.show = true;
          }
        }
        if (!category.show)
          for (let item of category.items) {
            if (
              (item.name&&item.name.toUpperCase().includes(filter)) ||
              (item.description&&item.description.toUpperCase().includes(filter))
            ) {
              item.show = true;
              category.show = true;
            }
          }
        if (category.strongMatch) {
          for (let item of category.items) {
            item.show = true;
          }
        }
      }
    }

    state.categories = categories;
  }

  return state;
}
const store = createStore(reducer,applyMiddleware(thunk));
function withdrawMoney(amount) {
  return {
    type: 'WITHDRAW',
    amount,
  };
}

// Even without middleware, you can dispatch an action:
store.dispatch(withdrawMoney(100));
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
        <InfoModal></InfoModal>
        <Switch>
          {/* <Route exact path='/selectLanguage' ><MenuLanguage  onLanguageSelect={this.onLanguageSelect}></MenuLanguage></Route> */}
          {/* <Route exact path='/orders' component={OrdersListComponent}></Route> */}
          <Route
            path="/messenger"
            component={() => {
              window.location.href = "https://m.me/restaurant.emenus";
              return null;
            }}
            />
            <Route exact path="/success" component={SuccessStripePage}></Route>
            <Route exact path="/mymenus" component={MyMenus}></Route>
            <Route exact path="/createpdfmenu" component={PdfMenuCreate}></Route>
            <Route exact path="/createphotomenu" component={PhotoMenuCreate}></Route>
            <Route path="/menudetail/:short_code" component={MenuEditCreate}></Route>
         
            
<Route exact path="/login" component={LoginPage}></Route>
       
          <Route path="/" component={App}></Route>
         
          </Switch></Suspense>
        
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
