import React,{useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon }  from "@fortawesome/react-fontawesome";
import  faMinusCircle  from "@fortawesome/fontawesome-free-solid/faMinusCircle";
import  faInfoCircle  from "@fortawesome/fontawesome-free-solid/faInfoCircle";
import "./Product.css";

import maskImage from "./assets/mask.png";
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);




  
  const InfoOverlay = (product) => {
    return (
      <div style={{ position: "fixed", width:'90vw', top: 20, right: 20, left: 20, bottom: 20, zIndex:9000000,backgroundColor:"white"}}>
        MORE INFO 
         {product.description}
      </div>
    )
  }
  

  


const useProgressiveImage = src => {  
  const [sourceLoaded, setSourceLoaded] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded 
}
const Product = ({ product }) => {

 
  function removeExtension(filename) {
    var lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) return filename;
    else return filename.substr(0, lastDotPosition);
  }
  const [showInfo, setShowInfo] = useState(false);

  const dispatch = useDispatch();
  const state = useSelector(state => {
    return state;
  });
  let topItems = product.qty > 0 && (
    <div
      className="topItems"
      onClickCapture={(event) => {
        product.onRemove(event, product);
      }}
    >
      <div className="leftTop">
        <i className="fas fa-minus-circle" />
        <FontAwesomeIcon icon={faMinusCircle} />
      </div>
      <div className="rightTop">x {product.qty}</div>
    </div>
  );

  let shadow =
    product.qty > 0
      ? "rgba(255, 255, 255, 0.2) 0px 0px 0px 100vw inset, rgba(107, 117, 102, 0.8) 0px 0px 11px 1vw"
      : "inset 0 0 0 100vw rgba(255, 255, 255, 0.1), rgba(137, 173, 115, 0) 0px 0px 12px 1vw";
  let scale = product.qty > 0 ? "scale(0.9)" : "scale(1)";
  let adminButton = <FontAwesomeIcon icon={faInfoCircle} />;
  adminButton = null;
  var imageName = '';
  var imageName2 = '';
  // if (product.image && product.image  instanceof String && product.image.contains('baziliqo')) {
    
  //   let imgPath = product.image.split('/');
  //   imageName2 = imgPath[imgPath.length - 1].split('.')[0];
  // }

  let content = showInfo ? <InfoOverlay></InfoOverlay> : <div>EMPTY</div>;
  if (!isSafari)
  {
    imageName = removeExtension(product.image) + '.webp';
  }
  else
  {
    imageName =removeExtension(product.image)+'.png';
    }
  if (product.image && product.image.length > 90)
  {
    imageName = product.image;
  }

  // const loaded = useProgressiveImage(imageName)
  return (
    <div className="mainProductView">
      {/* <LazyLoad > */}
      <div
        className="boxShaddowClass"
        style={{ boxShadow: shadow, transform: scale }}
      >
        <div
          className="cartProductGreen"
          onClick={() => {
            dispatch({
              type: "ADD_PRODUCT_TO_SHOPPING_CART",
              payload: product,
            });
          }}
          style={{ backgroundImage: `url(${imageName})`  }}
          // style={{ backgroundImage: `url(${loaded || null})`  }}
        >
          <div
            className="cartProductGreenMask"
            style={{ backgroundImage: "url(" + maskImage + ")" }}
          >
            {topItems}

            <img
              alt="combo"
              height="auto"
              id="bottomImage"
              width="100%"
              hidden
            />


            <div className="middleClass"></div>

            <div
              className={adminButton ? "leftBottomWithAdmin" : "leftBottom"}
              onClickCapture={(event) => {
                // product.onInfo(event, product);
              }}
            >
              {product.description.length ? <FontAwesomeIcon onClickCapture={() => {
                dispatch({ "type": "MODAL_PRODUCT_INFO", payload: { show: true, productData: {...product} } });
             
         
              }} icon={faInfoCircle} /> : null}
             
              {/* {showInfo ? <InfoOverlay product={product}></InfoOverlay> : null} */}
            </div>
          </div>
        </div>
      </div>

      {/* </LazyLoad> */}
      <div className="productTextLabelUnder">
        {product.name + "\n" + product.price + ' ' + product.currency.split(' - ')[0]}
      </div>
    
    </div>
  );
};

export default Product;
