import React from "react";
import Category from "./Category";



export default function PreviewMenu({categories,currency}) {
 
 
  
 
  return (
    <div style = {{backgroundColor:"black"}}>
      {categories ? (
        categories.map((category) => (
          <Category key={category.id} category={{...category, currency: currency}}></Category>
        ))
      ) : (
        <div>waiting for data...</div>
      )}
      <br></br>
      <br></br>
      <br></br>
   
      <br></br>
      <br></br>
      <br></br>
{/* {translate('example',state)} */}
    </div>
  );
}
