import React, { useState, useRef, useEffect, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import languages from "./languages";
import Button from "@material-ui/core/Button";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import {
  CardElement,
  useStripe,
  useElements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import axios from "axios";
import FindUsers from "./FindUsers";
import LinkQrForm from "./LinkQrForm";
import PhotoMenuCreate from "./PhotoMenuCreate";
import PdfMenuCreate from "./PdfMenuCreate";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripe = window.Stripe('pk_test_PF3AnUasTUO1ScUy6v2S6tWf');
let stripe = null;
if (window.Stripe) stripe = window.Stripe("pk_live_CCtk7w5mjlNOwXSt7tbOd3vG");
// const checkoutButton = null; document.querySelector('#checkout-button');

const addListener = (button, plan) => {
  button.currentTarget.addEventListener("click", () => {
    stripe.redirectToCheckout({
      lineItems: [
        {
          // Define the product and price in the Dashboard first, and use the price
          // ID in your client-side code.
          price: plan,
          quantity: 1,
        },
      ],
      mode: "subscription",
      successUrl: "https://e-menu.co.edit8.com/success",
      cancelUrl: "https://e-menu.co.edit8.com/login",
    });
  });
};

const getMenusFromServer = (setloadingData,dispatch) => {
    setloadingData(true);
    //your code...
    // console.log("back button...");
    // localStorage.setItem('user', rememberMe ? user : '');
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    if (userId) {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      axios
        .post(
          "https://admin.e-menu.co.edit8.com:8000/api/get_menus",
          { userId: userId },
          config
        )
        .then((response) => {
          console.log(" successfully: ", response);

          setloadingData(false);
          dispatch({
            type: "SET_USER_DETAILS",
            payload: {
              ...response.data.user,
              currentMenus: response.data.menus
            },
          });
          // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
        })
        .catch((err) => {
          console.error("Image post failed: ", err);
        });
    } else window.location.replace("https://e-menu.co.edit8.com:3000/login");
}
const createNewMenuOnServer = (
    currentlySelectedLanguage,
  currentMenu,
  setShowNewLanguage,dispatch,setloadingData
) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  };

  console.log("create new menu with new language..");
  axios
    .post(
      "https://admin.e-menu.co.edit8.com:8000/api/copy_in_new_language",
      { menu: currentMenu.short_code, language: currentlySelectedLanguage,sourceLanguage:currentMenu.language },
      config
    )
    .then((response) => {
        setShowNewLanguage(false);
        getMenusFromServer(setloadingData, dispatch);

    })
    .catch((err) => {
      alert("error");
    });
};
export default function MyMenus(props) {
  const history = useHistory();
    const [showEdit, setShowEdit] = useState(false);
    const [photoMenu, setPhotoMenu] = useState(false);
    const [pdfMenu, setPdfMenu] = useState(false);
  const dispatch = useDispatch();
  const [editMode, seteditMode] = useState(true);
  const currentMenus = useSelector((state) => state.menuData.user.currentMenus);
  const user = useSelector((state) => state.menuData.user);

  const [showStripe, setshowStripe] = useState(false);
  const unlimitedButton = (
    <button
      id="checkout-button"
      style={{
        padding: "1em",
        backgroundColor: "#1e8a24",
        color: "white",
        width: "70vw",
        margin: "0.2em",
        borderRadius: "5px",
      }}
      onClick={() => {}}
    >
      UNLIMITED €99
    </button>
  );
  const refUnlimited = useRef(unlimitedButton);

  const [showTransferForm, setshowTransferForm] = useState(false);
  const [showLinkQrForm, setshowLinkQrForm] = useState(false);
  const [selectedMenu, setselectedMenu] = useState(null);
  const [loadingData, setloadingData] = useState(true);
  const [showNewLanguage, setShowNewLanguage] = useState(false);
  const deleteMenu = (menu) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios 
      .post("https://admin.e-menu.co.edit8.com:8000/api/delete_menu", { menuData: menu }, config)
      .then((response) => {
        console.log(" successfully: ", response);
        console.log("back button...");
        // localStorage.setItem('user', rememberMe ? user : '');
        const userId = localStorage.getItem("userId");
        if (userId) {
          let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          };
          axios
            .post(
              "https://admin.e-menu.co.edit8.com:8000/api/get_menus",
              { userId: userId },
              config
            )
            .then((response) => {
              setloadingData(false);
              console.log(" successfully: ", response);
              dispatch({
                type: "SET_USER_DETAILS",
                payload: {
                  ...response.data.user,
                  currentMenus: response.data.menus,
                },
              });
              // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
            })
            .catch((err) => {
              console.error("Image post failed: ", err);
            });
        }
        // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
      })
      .catch((err) => {
        console.error("Image post failed: ", err);
      });
  };

  useEffect(() => {
    {
     getMenusFromServer(setloadingData,dispatch)
    }
    window.onpopstate = (e) => {
      //your code...
      setloadingData(true);
      console.log("back button...");
      // localStorage.setItem('user', rememberMe ? user : '');
      const userId = localStorage.getItem("userId");
      if (userId) {
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        };
        axios
          .post(
            "https://admin.e-menu.co.edit8.com:8000/api/get_menus",
            { userId: userId },
            config
          )
          .then((response) => {
            setloadingData(false);
            console.log(" successfully: ", response);
            dispatch({
              type: "SET_USER_DETAILS",
              payload: {
                ...response.data.user,
                currentMenus: response.data.menus,
              },
            });
            // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
          })
          .catch((err) => {
            console.error("Image post failed: ", err);
          });
      } else window.location.replace("https://e-menu.co.edit8.com/login");
    };
  }, []);

  return (
      <>
          {/* {photoMenu ? <PhotoMenuCreate ></PhotoMenuCreate>:null}
          {pdfMenu ? <PdfMenuCreate ></PdfMenuCreate>:null} */}
      {loadingData ? (
        <div>Loading...</div>
      ) : (
        <div
          style={{
            paddingTop: "1em",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {showNewLanguage ? (
            <NewLanguageForm
              createNewMenuOnServer={createNewMenuOnServer}
              setShowNewLanguage={setShowNewLanguage}
                              selectedMenu={selectedMenu}
                              setloadingData = {setloadingData}
            ></NewLanguageForm>
          ) : null}
          {showTransferForm ? (
            <FindUsers
              setshowTransferForm={setshowTransferForm}
              selectedMenu={selectedMenu}
            ></FindUsers>
          ) : null}
          {showLinkQrForm ? (
            <LinkQrForm
              setshowLinkQrForm={setshowLinkQrForm}
              selectedMenu={selectedMenu}
            ></LinkQrForm>
          ) : null}
          <img
            style={{ width: "200px" }}
            src="https://e-menupull.b-cdn.net/logo.png"
          ></img>
          <div>My Managed Menus</div>
          <br></br>
          {currentMenus &&
            currentMenus.filter((men)=>typeof(men.menuVenueName) != "undefined" ).map((menu) => (
              <div
                style={{
                  padding: "1em",
                  backgroundColor: "white",
                  color: "black",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "black",
                  width: "70vw",
                  margin: "0.2em",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  dispatch({ type: "LOAD_PREVIOUS_MENU", payload: menu });

                  setShowEdit(true);
                  history.push("/menudetail/" + menu.short_code);
                }}
                key={menu.id}
              >
                {menu &&
                  menu.menuVenueName +
                    "-" +
                    languages
                      .filter((lang) => lang["639-1"] === menu.language)
                      .map((lang) => lang.language)}
               
               <button
                  onClick={(e) => {
                    setselectedMenu(menu);
                    setShowNewLanguage(true);
                    e.stopPropagation();
                  }}
                >
                  new language
                </button>
                {user.admin ? (
                  <>
                    <button
                      onClickCapture={(e) => {
                        setselectedMenu(menu);
                        setshowTransferForm(!showTransferForm);
                        e.stopPropagation();
                      }}
                    >
                      TRANSFER{" "}
                    </button>
                    <button
                      onClickCapture={(e) => {
                        setselectedMenu(menu);

                        setshowLinkQrForm(!showLinkQrForm);
                        e.stopPropagation();
                      }}
                    >
                      Link QR
                    </button>
                  
                  </>
                ) : null}

<button
                      onClickCapture={(e) => {
                        deleteMenu(menu);
                        e.stopPropagation();
                      }}
                    >
                      delete
                    </button>
              </div>
            ))}
          <button
            style={{
              padding: "1em",
              backgroundColor: "#156319",
              color: "white",
              width: "70vw",
              margin: "0.2em",
              borderRadius: "5px",
            }}
              onClick={() => {
              


              setShowEdit(true);
              history.push("/menudetail/new");
            }}
          >
                          Create new menu
          </button>
          <button
            style={{
              padding: "1em",
              backgroundColor: "#156319",
              color: "white",
              width: "70vw",
              margin: "0.2em",
              borderRadius: "5px",
            }}
            onClick={() => {
                // setPhotoMenu(true);
                console.log("lets create photo menu...")
               history.push("createphotomenu");
            }}
          >
                          Create new menu using your CAMERA /  PHOTOS
          </button>
          <button
            style={{
              padding: "1em",
              backgroundColor: "#156319",
              color: "white",
              width: "70vw",
              margin: "0.2em",
              borderRadius: "5px",
            }}
            onClick={() => {
                setPdfMenu(true);
                console.log("lets create pdf menu...")
                history.push("createpdfmenu");
            }}
          >
            Create new menu using PDF menu file.
          </button>
            <br></br>
            <button onClick={() => window.location = "https://e-menupull.b-cdn.net/emenualerter.apk"}>Download Android -  Call the Waiter App </button>
            <br></br>
            <button onClick={() => window.location = "https://e-menupull.b-cdn.net/e-menu_alerter_windows_v0.1.zip"}>Download Windows - Call the Waiter App </button>
            <label>No need to install,it'sportable, just run the menualerterapp once you extract the zip</label>
          
        
                      <br></br>

                      <label style={{ width: "30em" }}>Once you login with the email and pass you created here, when users scan the menu, they will be able to see new button "call the waiter", which will send you alerts to this app. for support email: 
                      support@edit8.com</label>
          {currentMenus && currentMenus.length ? (
                          <>
                              
              {/* <button
                style={{
                  padding: "1em",
                  color: "black",
                  width: "70vw",
                  margin: "0.2em",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  seteditMode(false);
                  setShowEdit(true);
                }}
              >
                IMAGES NOT SHOWING UP AFTER USING FREE ALLOWANCE? <br></br>
                UPGRADE TO SOME OF OUR PREMIUM PACKAGES!
              </button> */}
             
              {user.admin ? (
                <>
                  <button
                    style={{
                      padding: "2em",
                      backgroundColor: "#27ba31",
                      color: "white",
                      width: "70vw",
                      margin: "0.2em",
                      borderRadius: "5px",
                    }}
                    onClick={(e) => {
                      addListener(e, "price_1GyeoD46HqAzkmI3raekFAJd");
                      e.currentTarget.click();
                    }}
                  >
                    PREMIUM BASIC €10 (0-10 tables)
                  </button>
                  <button
                    style={{
                      padding: "2em",
                      backgroundColor: "#26ad2f",
                      color: "white",
                      width: "70vw",
                      margin: "0.2em",
                      borderRadius: "5px",
                    }}
                    onClick={(e) => {
                      addListener(e, "price_1Gyeoj46HqAzkmI3emO47PQt");
                      e.currentTarget.click();
                    }}
                  >
                    PREMIUM €29 (10-20 tables)
                  </button>

                  <button
                    style={{
                      padding: "2em",
                      backgroundColor: "#23a12b",
                      color: "white",
                      width: "70vw",
                      margin: "0.2em",
                      borderRadius: "5px",
                    }}
                    onClick={(e) => {
                      addListener(e, "price_1GyepC46HqAzkmI3TmKRR9qi");
                      e.currentTarget.click();
                    }}
                  >
                    PREMIUM Plus €49 (20-50 tables)
                  </button>
                  <button
                    style={{
                      padding: "2em",
                      backgroundColor: "#209927",
                      color: "white",
                      width: "70vw",
                      margin: "0.2em",
                      borderRadius: "5px",
                    }}
                    onClick={(e) => {
                      addListener(e, "price_1Gws9S46HqAzkmI3sTUuv2TC");
                      e.currentTarget.click();
                    }}
                  >
                    PREMIUM Extra €79 (50-100 tables)
                  </button>
                  <button
                    id="checkout-button"
                    style={{
                      padding: "2em",
                      backgroundColor: "#1e8a24",
                      color: "white",
                      width: "70vw",
                      margin: "0.2em",
                      borderRadius: "5px",
                    }}
                    onClick={(e) => {
                      addListener(e, "price_1Gws3b46HqAzkmI3ayQfy8Ls");
                      e.currentTarget.click();
                    }}
                  >
                    UNLIMITED €99
                  </button>
                  <div>Prices are per month per venue.</div>
                </>
              ) : null}
            </>
          ) : null}
          <br></br>

          {showStripe && showStripe ? (
            <div
              style={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                zIndex: 3000,
                position: "fixed",
              }}
            ></div>
          ) : null}
        </div>
      )}
    </>
  );
}

const NewLanguageForm = ({
  selectedMenu,
  setShowNewLanguage,
    createNewMenuOnServer,
  setloadingData
}) => {

    const dispatch = useDispatch();
  const [currentlySelectedLanguage, setCurrentlySelectedLanguage] = useState(
    null
  );
  return (
    <div
      style={{
        background: "white",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 12342342343234,
      }}
    >
      <div>COPY EXISTING MENU DATA AND CREATE NEW LANGUAGE MENU</div>
      <br></br>
      <div>select new language</div>
      <div
        style={{
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          overflow: "scroll",
          justifyContent: "center",
        }}
      >
        {languages.map((lang) => (
          <button
            style={{ width: "80%", height: "2em" }}
            key={lang.language}
            onClick={() => setCurrentlySelectedLanguage(lang)}
          >
            {lang.language}
          </button>
        ))}
      </div>
      <button
              onClick={() => {
                  createNewMenuOnServer(currentlySelectedLanguage, selectedMenu, setShowNewLanguage, dispatch
                  ,setloadingData);

              }
        }
      >
        OK
      </button>
      <button onClick={() => setShowNewLanguage(false)}>Cancel</button>
    </div>
  );
};
