    import React,{useState} from 'react'
    import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
let config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token")
    },
};

const LinkQrForm = ({ setshowLinkQrForm, selectedMenu }) => {
    const [selectedNumber, setSelectedNumber] = useState(null);
    const linkNow = (selectedNumber) => {
     
        axios
        .post("https://admin.e-menu.co.edit8.com:8000/api/link_menu", { number: selectedNumber,short_code:selectedMenu.short_code}, config)
    .then((response) => {
        console.log(" successfully: ", response);
        setshowLinkQrForm(false);
        if (response.data.success)
            window.alert("success");
        else
        window.alert("failed");

        // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
    })
    .catch((err) => {
        console.error("  failed: ", err.message);
        window.alert(err);
    });
 }
        return (
            <div style = {{display:"flex",flexDirection:"column",justifyContent:"center",alignContent:"center", alignItems:"center",top:20,left:20,right:20,bottom:20,position:"fixed",backgroundColor:"white",zIndex:1000000}}>
                <label> LINK numbered QR short code to this menu</label>
                <TextField onChange={(e) => setSelectedNumber(e.currentTarget.value)} value={selectedNumber} ></TextField>
                <Button onClick={() => linkNow(selectedNumber)}>LINK NOW</Button>
                <Button onClick={() => setshowLinkQrForm(false)}>Cancel</Button>
            </div>
        )
    }
    
    export default LinkQrForm
    