    import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from "@material-ui/core/Button";
const InfoModal = (product) => {
    const productInfoModal = useSelector(state => state.productInfoModal);
    const dispatch = useDispatch();
    return (
        <>
            {productInfoModal.show ? <div
                    style={{
                        zIndex: 1000000,
                        position: "fixed",
                        top: 20, left: 20, right: 20,bottom:20,
                        borderRadius: 7,
                        backgroundColor: "white",
                    
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignContent: "center",
                        justifyContent:"center"
                    }}
                    onClickCapture={() => { dispatch({ type: "MODAL_PRODUCT_INFO", payload: { ...productInfoModal,show:false } }) }}
                > 
                <img style = {{width:300}} src={productInfoModal.productData.image} ></img>
                <div>{productInfoModal.productData.name}</div>
                <div>{productInfoModal.productData.description}</div>
                    <Button  onClickCapture={() => { dispatch({ type: "MODAL_PRODUCT_INFO", payload: { ...productInfoModal,show:false } }) }} >CLOSE</Button>
        </div> : null
            }
            </>
        )
    }
    
    export default InfoModal
    