    import React,{useState} from 'react'
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";





const FindUsers = ({ setshowTransferForm, selectedMenu }) => {
   
    const getUsersEmails = () => {
        let knowUsersEmailList = [];
        knownUsers.forEach(user => {
            knowUsersEmailList.push(user.email);
        });
        return knowUsersEmailList;
    }
    
    
    const dispatch = useDispatch();
    const [selectedUser, setselectedUser] = useState(null);
    const [userEmail, setuserEmail] = useState('')
    const knownUsers = useSelector(state => state.menuData.knownUsers);
    let config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    let getUserfromknownusersByEmail = (email) => {
        let user = null;
        knownUsers.forEach(usr => {
            if (usr.email === email)
                user = usr;
        });
        return user;
    }
    let transferUser = (user) => {
        axios
            .post("https://e-menu.co.edit8.com:2000/transfer_user", { user: user,menu:selectedMenu}, config)
        .then((response) => {
            console.log(" successfully: ", response);
            setshowTransferForm(false);
            window.alert("success");

            // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
        })
        .catch((err) => {
            console.error("  failed: ", err);
            window.alert("failure");
        });
    }
    let queryServer = () => {
        axios
            .post("https://e-menu.co.edit8.com:2000/find_user", { email: userEmail }, config)
        .then((response) => {
            console.log(" successfully: ", response);
            dispatch({
                type: "ADD_KNOWN_USER", payload:response
            })
            // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
        })
        .catch((err) => {
            console.error("Image post failed: ", err);
        });
    }
        return (
            <div style = {{display:"flex",flexDirection:"column",justifyContent:"center",alignContent:"center", alignItems:"center",top:20,left:20,right:20,bottom:20,position:"fixed",backgroundColor:"white",zIndex:1000000}}><div>Find users by email:</div>
                <TextField  value = {userEmail} onChange = {(e)=>setuserEmail(e.currentTarget.value)}></TextField>
                <Button onClick={() => queryServer()}>FIND</Button>
                
                <div>Users matching this criteria:</div>
                {knownUsers.length ? <Dropdown
                    key="users"
                    options={getUsersEmails()}
                    onChange={(e) => {
                        setselectedUser(e.value);
                        // dispatch({
                        //     type: "CHANGE_MENU_COUNTRY",
                        //     payload: e.value,
                        // });
                        console.log(e);
                    }}
                    value = {selectedUser}
                    placeholder="Select user"
                /> : null}
                {selectedUser ? <Button onClick = {()=>transferUser(getUserfromknownusersByEmail(selectedUser))} >TRANSFER MENU TO THIS USER!</Button> : null}
                <Button onClick={() => setshowTransferForm(false)}>CANCEL</Button>
            </div>
        )
    }
    
    export default FindUsers
    