    import React,{useState} from 'react'
import { Button } from '@material-ui/core'
    
const PdfMenuCreate = () => {
    const [pdf, setPdf] = useState(null);
        return (
            <div style={{
                zIndex:10000000000000,
                display: "flex",
              position: "fixed",
              width: "100vw",
                height:"100vh",
                top: 0,
               
                left: 0,
              
                backgroundColor: "white",
                alignItems: "center",
                alignContent: "center",
                      flexdirection: "column",
                justifyContent:"center"
              }} >
                let's create menu from PDF file!
                 
                <Button>Select PDF</Button>
            </div>
        )
    }
    
    export default PdfMenuCreate
    