import React, { useRef, useState } from 'react';
import axios from 'axios';
import useFileUpload from 'react-use-file-upload';
import Loader from "react-js-loader";
import { useSelector, useDispatch } from "react-redux";
const UploadMenu = ({ setShowUploadMenu }) => {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();
  const dispatch = useDispatch();
const [showLoader,setShowLoader] = useState(false);
  const inputRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = createFormData();
    let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      };

    //   let fd = new FormData();
    //   fd.append("name", product.id + ".png");
    //   fd.append("file", product.imageData);
    try {
        axios
        .post("https://admin.e-menu.co.edit8.com:8000/api/upload_zip_photos", formData, config)
        .then((response) => {
          console.log("zip posted successfully: ", response);
          dispatch({ type: "ADD_CATEGORY_FROM_ZIP", payload: response.data });
          setShowUploadMenu(false)
          // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
        })
        .catch((err) => {
          console.error("zip post failed: ", err);
         
        });
    //   axios.post('https://admin.e-menu.co.edit8.com:8000/api/upload_images_zip', formData, {
    //     'content-type': 'multipart/form-data',
    //   });
    } catch (error) {
      console.error('Failed to submit files.');
    }
  };

const [selectedZipFile,setSelectedZipFile] = useState()

const handleUploadImage = () => {
    setShowLoader(true);
    let fd = new FormData();
  //  fd.append("name", selectedZipFile.name);
    
    fd.append("file", selectedZipFile.file);

    let config = {
      
        headers: {
          //  "boundary":fd.boundary,
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      };

     
      // fd.append("enctype", "multipart/form-data");
   
    try {
        axios
        .post("https://admin.e-menu.co.edit8.com:8000/api/upload_zip_photos", fd, config)
        .then((response) => {
          console.log("zip posted successfully: ", response);
          dispatch({ type: "ADD_CATEGORY_FROM_ZIP", payload: response.data });
          setShowUploadMenu(false)
          setShowLoader(false);
          // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
        })
        .catch((err) => {
            setShowLoader(false);
          console.error("zip post failed: ", err);
         
        });
    //   axios.post('https://admin.e-menu.co.edit8.com:8000/api/upload_images_zip', formData, {
    //     'content-type': 'multipart/form-data',
    //   });
    } catch (error) {
      console.error('Failed to submit files.');
    }
}


  const handleLoadImage = (ee) => {
      console.log("handleLoadImage")
    var file1 = ee.target.files[0];
    var reader = new FileReader();
    // reader.onload = (e) => {
    //     console.log(e.target)
     
    //   setSelectedZipFile ({file: e.target.result,name:file.name});
    // };
    // reader.readAsDataURL(file);
    setSelectedZipFile({file:file1,name:file1.name})
  };

  return (
    <div css={CSS}>
        <input
          type="file"
          id="user_file"
          name="file"
          onChange={(e) => {
            handleLoadImage(e);
          }}
        />
        <button onClick = {()=> handleUploadImage()}>UPLOAD NOW</button>
       {showLoader?<Loader type="box-rotate-x" bgColor={"#FFFFFF"} title={"box-rotate-x"} color={'#FFFFFF'} size={100} />:null}

      

     

       
      
    </div>
  );
};
export default UploadMenu;