const currencies = [
    {
        "code": "AED",
        "number": 784,
        "decimals": 2,
        "name": "United Arab Emirates dirham"
    },
    {
        "code": "AFN",
        "number": 971,
        "decimals": 2,
        "name": "Afghan afghani"
    },
    {
        "code": "ALL",
        "number": 8,
        "decimals": 2,
        "name": "Albanian lek"
    },
    {
        "code": "AMD",
        "number": 51,
        "decimals": 2,
        "name": "Armenian dram"
    },
    {
        "code": "ANG",
        "number": 532,
        "decimals": 2,
        "name": "Netherlands Antillean guilder"
    },
    {
        "code": "AOA",
        "number": 973,
        "decimals": 2,
        "name": "Angolan kwanza"
    },
    {
        "code": "ARS",
        "number": 32,
        "decimals": 2,
        "name": "Argentine peso"
    },
    {
        "code": "AUD",
        "number": 36,
        "decimals": 2,
        "name": "Australian dollar"
    },
    {
        "code": "AWG",
        "number": 533,
        "decimals": 2,
        "name": "Aruban florin"
    },
    {
        "code": "AZN",
        "number": 944,
        "decimals": 2,
        "name": "Azerbaijani manat"
    },
    {
        "code": "BAM",
        "number": 977,
        "decimals": 2,
        "name": "Bosnia and Herzegovina convertible mark"
    },
    {
        "code": "BBD",
        "number": 52,
        "decimals": 2,
        "name": "Barbados dollar"
    },
    {
        "code": "BDT",
        "number": 50,
        "decimals": 2,
        "name": "Bangladeshi taka"
    },
    {
        "code": "BGN",
        "number": 975,
        "decimals": 2,
        "name": "Bulgarian lev"
    },
    {
        "code": "BHD",
        "number": 48,
        "decimals": 3,
        "name": "Bahraini dinar"
    },
    {
        "code": "BIF",
        "number": 108,
        "decimals": 0,
        "name": "Burundian franc"
    },
    {
        "code": "BMD",
        "number": 60,
        "decimals": 2,
        "name": "Bermudian dollar (customarily known as Bermuda dollar)"
    },
    {
        "code": "BND",
        "number": 96,
        "decimals": 2,
        "name": "Brunei dollar"
    },
    {
        "code": "BOB",
        "number": 68,
        "decimals": 2,
        "name": "Boliviano"
    },
    {
        "code": "BOV",
        "number": 984,
        "decimals": 2,
        "name": "Bolivian Mvdol (funds code)"
    },
    {
        "code": "BRL",
        "number": 986,
        "decimals": 2,
        "name": "Brazilian real"
    },
    {
        "code": "BSD",
        "number": 44,
        "decimals": 2,
        "name": "Bahamian dollar"
    },
    {
        "code": "BTN",
        "number": 64,
        "decimals": 2,
        "name": "Bhutanese ngultrum"
    },
    {
        "code": "BWP",
        "number": 72,
        "decimals": 2,
        "name": "Botswana pula"
    },
    {
        "code": "BYR",
        "number": 974,
        "decimals": 0,
        "name": "Belarusian ruble"
    },
    {
        "code": "BZD",
        "number": 84,
        "decimals": 2,
        "name": "Belize dollar"
    },
    {
        "code": "CAD",
        "number": 124,
        "decimals": 2,
        "name": "Canadian dollar"
    },
    {
        "code": "CDF",
        "number": 976,
        "decimals": 2,
        "name": "Congolese franc"
    },
    {
        "code": "CHE",
        "number": 947,
        "decimals": 2,
        "name": "WIR Euro (complementary currency)"
    },
    {
        "code": "CHF",
        "number": 756,
        "decimals": 2,
        "name": "Swiss franc"
    },
    {
        "code": "CHW",
        "number": 948,
        "decimals": 2,
        "name": "WIR Franc (complementary currency)"
    },
    {
        "code": "CLF",
        "number": 990,
        "decimals": 0,
        "name": "Unidad de Fomento (funds code)"
    },
    {
        "code": "CLP",
        "number": 152,
        "decimals": 0,
        "name": "Chilean peso"
    },
    {
        "code": "CNY",
        "number": 156,
        "decimals": 2,
        "name": "Chinese yuan"
    },
    {
        "code": "COP",
        "number": 170,
        "decimals": 2,
        "name": "Colombian peso"
    },
    {
        "code": "COU",
        "number": 970,
        "decimals": 2,
        "name": "Unidad de Valor Real"
    },
    {
        "code": "CRC",
        "number": 188,
        "decimals": 2,
        "name": "Costa Rican colon"
    },
    {
        "code": "CUC",
        "number": 931,
        "decimals": 2,
        "name": "Cuban convertible peso"
    },
    {
        "code": "CUP",
        "number": 192,
        "decimals": 2,
        "name": "Cuban peso"
    },
    {
        "code": "CVE",
        "number": 132,
        "decimals": 0,
        "name": "Cape Verde escudo"
    },
    {
        "code": "CZK",
        "number": 203,
        "decimals": 2,
        "name": "Czech koruna"
    },
    {
        "code": "DJF",
        "number": 262,
        "decimals": 0,
        "name": "Djiboutian franc"
    },
    {
        "code": "DKK",
        "number": 208,
        "decimals": 2,
        "name": "Danish krone"
    },
    {
        "code": "DOP",
        "number": 214,
        "decimals": 2,
        "name": "Dominican peso"
    },
    {
        "code": "DZD",
        "number": 12,
        "decimals": 2,
        "name": "Algerian dinar"
    },
    {
        "code": "EGP",
        "number": 818,
        "decimals": 2,
        "name": "Egyptian pound"
    },
    {
        "code": "ERN",
        "number": 232,
        "decimals": 2,
        "name": "Eritrean nakfa"
    },
    {
        "code": "ETB",
        "number": 230,
        "decimals": 2,
        "name": "Ethiopian birr"
    },
    {
        "code": "EUR",
        "number": 978,
        "decimals": 2,
        "name": "Euro"
    },
    {
        "code": "FJD",
        "number": 242,
        "decimals": 2,
        "name": "Fiji dollar"
    },
    {
        "code": "FKP",
        "number": 238,
        "decimals": 2,
        "name": "Falkland Islands pound"
    },
    {
        "code": "GBP",
        "number": 826,
        "decimals": 2,
        "name": "Pound sterling"
    },
    {
        "code": "GEL",
        "number": 981,
        "decimals": 2,
        "name": "Georgian lari"
    },
    {
        "code": "GHS",
        "number": 936,
        "decimals": 2,
        "name": "Ghanaian cedi"
    },
    {
        "code": "GIP",
        "number": 292,
        "decimals": 2,
        "name": "Gibraltar pound"
    },
    {
        "code": "GMD",
        "number": 270,
        "decimals": 2,
        "name": "Gambian dalasi"
    },
    {
        "code": "GNF",
        "number": 324,
        "decimals": 0,
        "name": "Guinean franc"
    },
    {
        "code": "GTQ",
        "number": 320,
        "decimals": 2,
        "name": "Guatemalan quetzal"
    },
    {
        "code": "GYD",
        "number": 328,
        "decimals": 2,
        "name": "Guyanese dollar"
    },
    {
        "code": "HKD",
        "number": 344,
        "decimals": 2,
        "name": "Hong Kong dollar"
    },
    {
        "code": "HNL",
        "number": 340,
        "decimals": 2,
        "name": "Honduran lempira"
    },
    {
        "code": "HRK",
        "number": 191,
        "decimals": 2,
        "name": "Croatian kuna"
    },
    {
        "code": "HTG",
        "number": 332,
        "decimals": 2,
        "name": "Haitian gourde"
    },
    {
        "code": "HUF",
        "number": 348,
        "decimals": 2,
        "name": "Hungarian forint"
    },
    {
        "code": "IDR",
        "number": 360,
        "decimals": 2,
        "name": "Indonesian rupiah"
    },
    {
        "code": "ILS",
        "number": 376,
        "decimals": 2,
        "name": "Israeli new shekel"
    },
    {
        "code": "INR",
        "number": 356,
        "decimals": 2,
        "name": "Indian rupee"
    },
    {
        "code": "IQD",
        "number": 368,
        "decimals": 3,
        "name": "Iraqi dinar"
    },
    {
        "code": "IRR",
        "number": 364,
        "decimals": 0,
        "name": "Iranian rial"
    },
    {
        "code": "ISK",
        "number": 352,
        "decimals": 0,
        "name": "Icelandic króna"
    },
    {
        "code": "JMD",
        "number": 388,
        "decimals": 2,
        "name": "Jamaican dollar"
    },
    {
        "code": "JOD",
        "number": 400,
        "decimals": 3,
        "name": "Jordanian dinar"
    },
    {
        "code": "JPY",
        "number": 392,
        "decimals": 0,
        "name": "Japanese yen"
    },
    {
        "code": "KES",
        "number": 404,
        "decimals": 2,
        "name": "Kenyan shilling"
    },
    {
        "code": "KGS",
        "number": 417,
        "decimals": 2,
        "name": "Kyrgyzstani som"
    },
    {
        "code": "KHR",
        "number": 116,
        "decimals": 2,
        "name": "Cambodian riel"
    },
    {
        "code": "KMF",
        "number": 174,
        "decimals": 0,
        "name": "Comoro franc"
    },
    {
        "code": "KPW",
        "number": 408,
        "decimals": 0,
        "name": "North Korean won"
    },
    {
        "code": "KRW",
        "number": 410,
        "decimals": 0,
        "name": "South Korean won"
    },
    {
        "code": "KWD",
        "number": 414,
        "decimals": 3,
        "name": "Kuwaiti dinar"
    },
    {
        "code": "KYD",
        "number": 136,
        "decimals": 2,
        "name": "Cayman Islands dollar"
    },
    {
        "code": "KZT",
        "number": 398,
        "decimals": 2,
        "name": "Kazakhstani tenge"
    },
    {
        "code": "LAK",
        "number": 418,
        "decimals": 0,
        "name": "Lao kip"
    },
    {
        "code": "LBP",
        "number": 422,
        "decimals": 0,
        "name": "Lebanese pound"
    },
    {
        "code": "LKR",
        "number": 144,
        "decimals": 2,
        "name": "Sri Lankan rupee"
    },
    {
        "code": "LRD",
        "number": 430,
        "decimals": 2,
        "name": "Liberian dollar"
    },
    {
        "code": "LSL",
        "number": 426,
        "decimals": 2,
        "name": "Lesotho loti"
    },
    {
        "code": "LTL",
        "number": 440,
        "decimals": 2,
        "name": "Lithuanian litas"
    },
    {
        "code": "LVL",
        "number": 428,
        "decimals": 2,
        "name": "Latvian lats"
    },
    {
        "code": "LYD",
        "number": 434,
        "decimals": 3,
        "name": "Libyan dinar"
    },
    {
        "code": "MAD",
        "number": 504,
        "decimals": 2,
        "name": "Moroccan dirham"
    },
    {
        "code": "MDL",
        "number": 498,
        "decimals": 2,
        "name": "Moldovan leu"
    },
    {
        "code": "MGA",
        "number": 969,
        "decimals": "0.7[8]",
        "name": "Malagasy ariary"
    },
    {
        "code": "MKD",
        "number": 807,
        "decimals": 0,
        "name": "Macedonian denar"
    },
    {
        "code": "MMK",
        "number": 104,
        "decimals": 0,
        "name": "Myanma kyat"
    },
    {
        "code": "MNT",
        "number": 496,
        "decimals": 2,
        "name": "Mongolian tugrik"
    },
    {
        "code": "MOP",
        "number": 446,
        "decimals": 2,
        "name": "Macanese pataca"
    },
    {
        "code": "MRO",
        "number": 478,
        "decimals": "0.7[8]",
        "name": "Mauritanian ouguiya"
    },
    {
        "code": "MUR",
        "number": 480,
        "decimals": 2,
        "name": "Mauritian rupee"
    },
    {
        "code": "MVR",
        "number": 462,
        "decimals": 2,
        "name": "Maldivian rufiyaa"
    },
    {
        "code": "MWK",
        "number": 454,
        "decimals": 2,
        "name": "Malawian kwacha"
    },
    {
        "code": "MXN",
        "number": 484,
        "decimals": 2,
        "name": "Mexican peso"
    },
    {
        "code": "MXV",
        "number": 979,
        "decimals": 2,
        "name": "Mexican Unidad de Inversion (UDI) (funds code)"
    },
    {
        "code": "MYR",
        "number": 458,
        "decimals": 2,
        "name": "Malaysian ringgit"
    },
    {
        "code": "MZN",
        "number": 943,
        "decimals": 2,
        "name": "Mozambican metical"
    },
    {
        "code": "NAD",
        "number": 516,
        "decimals": 2,
        "name": "Namibian dollar"
    },
    {
        "code": "NGN",
        "number": 566,
        "decimals": 2,
        "name": "Nigerian naira"
    },
    {
        "code": "NIO",
        "number": 558,
        "decimals": 2,
        "name": "Nicaraguan córdoba"
    },
    {
        "code": "NOK",
        "number": 578,
        "decimals": 2,
        "name": "Norwegian krone"
    },
    {
        "code": "NPR",
        "number": 524,
        "decimals": 2,
        "name": "Nepalese rupee"
    },
    {
        "code": "NZD",
        "number": 554,
        "decimals": 2,
        "name": "New Zealand dollar"
    },
    {
        "code": "OMR",
        "number": 512,
        "decimals": 3,
        "name": "Omani rial"
    },
    {
        "code": "PAB",
        "number": 590,
        "decimals": 2,
        "name": "Panamanian balboa"
    },
    {
        "code": "PEN",
        "number": 604,
        "decimals": 2,
        "name": "Peruvian nuevo sol"
    },
    {
        "code": "PGK",
        "number": 598,
        "decimals": 2,
        "name": "Papua New Guinean kina"
    },
    {
        "code": "PHP",
        "number": 608,
        "decimals": 2,
        "name": "Philippine peso"
    },
    {
        "code": "PKR",
        "number": 586,
        "decimals": 2,
        "name": "Pakistani rupee"
    },
    {
        "code": "PLN",
        "number": 985,
        "decimals": 2,
        "name": "Polish złoty"
    },
    {
        "code": "PYG",
        "number": 600,
        "decimals": 0,
        "name": "Paraguayan guaraní"
    },
    {
        "code": "QAR",
        "number": 634,
        "decimals": 2,
        "name": "Qatari riyal"
    },
    {
        "code": "RON",
        "number": 946,
        "decimals": 2,
        "name": "Romanian new leu"
    },
    {
        "code": "RSD",
        "number": 941,
        "decimals": 2,
        "name": "Serbian dinar"
    },
    {
        "code": "RUB",
        "number": 643,
        "decimals": 2,
        "name": "Russian rouble"
    },
    {
        "code": "RWF",
        "number": 646,
        "decimals": 0,
        "name": "Rwandan franc"
    },
    {
        "code": "SAR",
        "number": 682,
        "decimals": 2,
        "name": "Saudi riyal"
    },
    {
        "code": "SBD",
        "number": 90,
        "decimals": 2,
        "name": "Solomon Islands dollar"
    },
    {
        "code": "SCR",
        "number": 690,
        "decimals": 2,
        "name": "Seychelles rupee"
    },
    {
        "code": "SDG",
        "number": 938,
        "decimals": 2,
        "name": "Sudanese pound"
    },
    {
        "code": "SEK",
        "number": 752,
        "decimals": 2,
        "name": "Swedish krona/kronor"
    },
    {
        "code": "SGD",
        "number": 702,
        "decimals": 2,
        "name": "Singapore dollar"
    },
    {
        "code": "SHP",
        "number": 654,
        "decimals": 2,
        "name": "Saint Helena pound"
    },
    {
        "code": "SLL",
        "number": 694,
        "decimals": 0,
        "name": "Sierra Leonean leone"
    },
    {
        "code": "SOS",
        "number": 706,
        "decimals": 2,
        "name": "Somali shilling"
    },
    {
        "code": "SRD",
        "number": 968,
        "decimals": 2,
        "name": "Surinamese dollar"
    },
    {
        "code": "SSP",
        "number": 728,
        "decimals": 2,
        "name": "South Sudanese pound"
    },
    {
        "code": "STD",
        "number": 678,
        "decimals": 0,
        "name": "São Tomé and Príncipe dobra"
    },
    {
        "code": "SYP",
        "number": 760,
        "decimals": 2,
        "name": "Syrian pound"
    },
    {
        "code": "SZL",
        "number": 748,
        "decimals": 2,
        "name": "Swazi lilangeni"
    },
    {
        "code": "THB",
        "number": 764,
        "decimals": 2,
        "name": "Thai baht"
    },
    {
        "code": "TJS",
        "number": 972,
        "decimals": 2,
        "name": "Tajikistani somoni"
    },
    {
        "code": "TMT",
        "number": 934,
        "decimals": 2,
        "name": "Turkmenistani manat"
    },
    {
        "code": "TND",
        "number": 788,
        "decimals": 3,
        "name": "Tunisian dinar"
    },
    {
        "code": "TOP",
        "number": 776,
        "decimals": 2,
        "name": "Tongan paʻanga"
    },
    {
        "code": "TRY",
        "number": 949,
        "decimals": 2,
        "name": "Turkish lira"
    },
    {
        "code": "TTD",
        "number": 780,
        "decimals": 2,
        "name": "Trinidad and Tobago dollar"
    },
    {
        "code": "TWD",
        "number": 901,
        "decimals": 2,
        "name": "New Taiwan dollar"
    },
    {
        "code": "TZS",
        "number": 834,
        "decimals": 2,
        "name": "Tanzanian shilling"
    },
    {
        "code": "UAH",
        "number": 980,
        "decimals": 2,
        "name": "Ukrainian hryvnia"
    },
    {
        "code": "UGX",
        "number": 800,
        "decimals": 2,
        "name": "Ugandan shilling"
    },
    {
        "code": "USD",
        "number": 840,
        "decimals": 2,
        "name": "United States dollar"
    },
    {
        "code": "USN",
        "number": 997,
        "decimals": 2,
        "name": "United States dollar (next day) (funds code)"
    },
    {
        "code": "USS",
        "number": 998,
        "decimals": 2,
        "name": "United States dollar (same day) (funds code) (one source[who?] claims it is no longer used, but it is still on the ISO 4217-MA list)"
    },
    {
        "code": "UYI",
        "number": 940,
        "decimals": 0,
        "name": "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)"
    },
    {
        "code": "UYU",
        "number": 858,
        "decimals": 2,
        "name": "Uruguayan peso"
    },
    {
        "code": "UZS",
        "number": 860,
        "decimals": 2,
        "name": "Uzbekistan som"
    },
    {
        "code": "VEF",
        "number": 937,
        "decimals": 2,
        "name": "Venezuelan bolívar fuerte"
    },
    {
        "code": "VND",
        "number": 704,
        "decimals": 0,
        "name": "Vietnamese dong"
    },
    {
        "code": "VUV",
        "number": 548,
        "decimals": 0,
        "name": "Vanuatu vatu"
    },
    {
        "code": "WST",
        "number": 882,
        "decimals": 2,
        "name": "Samoan tala"
    },
    {
        "code": "XAF",
        "number": 950,
        "decimals": 0,
        "name": "CFA franc BEAC"
    },
    {
        "code": "XAG",
        "number": 961,
        "decimals": ".",
        "name": "Silver (one troy ounce)"
    },
    {
        "code": "XAU",
        "number": 959,
        "decimals": ".",
        "name": "Gold (one troy ounce)"
    },
    {
        "code": "XBA",
        "number": 955,
        "decimals": ".",
        "name": "European Composite Unit (EURCO) (bond market unit)"
    },
    {
        "code": "XBB",
        "number": 956,
        "decimals": ".",
        "name": "European Monetary Unit (E.M.U.-6) (bond market unit)"
    },
    {
        "code": "XBC",
        "number": 957,
        "decimals": ".",
        "name": "European Unit of Account 9 (E.U.A.-9) (bond market unit)"
    },
    {
        "code": "XBD",
        "number": 958,
        "decimals": ".",
        "name": "European Unit of Account 17 (E.U.A.-17) (bond market unit)"
    },
    {
        "code": "XCD",
        "number": 951,
        "decimals": 2,
        "name": "East Caribbean dollar"
    },
    {
        "code": "XDR",
        "number": 960,
        "decimals": ".",
        "name": "Special drawing rights"
    },
    {
        "code": "XFU",
        "number": "Nil",
        "decimals": ".",
        "name": "UIC franc (special settlement currency)"
    },
    {
        "code": "XOF",
        "number": 952,
        "decimals": 0,
        "name": "CFA franc BCEAO"
    },
    {
        "code": "XPD",
        "number": 964,
        "decimals": ".",
        "name": "Palladium (one troy ounce)"
    },
    {
        "code": "XPF",
        "number": 953,
        "decimals": 0,
        "name": "CFP franc"
    },
    {
        "code": "XPT",
        "number": 962,
        "decimals": ".",
        "name": "Platinum (one troy ounce)"
    },
    {
        "code": "XTS",
        "number": 963,
        "decimals": ".",
        "name": "Code reserved for testing purposes"
    },
    {
        "code": "XXX",
        "number": 999,
        "decimals": ".",
        "name": "No currency"
    },
    {
        "code": "YER",
        "number": 886,
        "decimals": 2,
        "name": "Yemeni rial"
    },
    {
        "code": "ZAR",
        "number": 710,
        "decimals": 2,
        "name": "South African rand"
    },
    {
        "code": "ZMW",
        "number": 967,
        "decimals": 2,
        "name": "Zambian kwacha"
    }
];
export default currencies;