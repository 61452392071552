import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import languages from "./languages.js";
import countries from "./countries.js";
import QRCode from "qrcode.react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete  from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import useFileUpload from 'react-use-file-upload';
// import Camera, { FACING_MODES } from "react-html5-camera-photo";
// import "react-html5-camera-photo/build/css/index.css";
import PreviewMenu from "./PreviewMenu";
import currencies from "./Currencies.js";
import countriesJson from './countriesJson.js'
import { default as AutoComplete2 } from 'react-google-autocomplete';
// import Geosuggest from 'react-geosuggest';


import axios from "axios";

import "./App.css";
import stockimages from "./stockFilesList.js";
import InfoModal from "./InfoModal.js";
import UploadMenu from "./UploadMenu.js";
import { truncate } from "lodash";
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const qs = require("qs");
const getLanguages = (languages) => {
  let result = [];
  for (let i = 0; i < languages.length; i++) {
    result.push(languages[i]["639-1"]);
  }
  return result;
};
const getCurrencies = (currencies) => {
  let result = [];
  for (let i = 0; i < currencies.length; i++) {
    result.push(currencies[i].code + " - " + currencies[i].name);
  }
  return result;
};
const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
// const CameraTakeImage = ({ product }) => {
//   return (
//     <Camera
//       idealFacingMode={FACING_MODES.ENVIRONMENT}
//       onTakePhoto={(dataUri) => {
//         console.log(dataUri);
//       }}
//     />
//   );
// };

const EditProductSegment = ({ setShowEditProduct, product, category }) => {
  const [selectedImage, setselectedImage] = useState(product.image);

  const handleLoadImage = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = (e) => {
      var img = document.createElement("img");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 300;
        var MAX_HEIGHT = 300;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL("image/png");
        console.log(dataurl);
        setselectedImage(dataurl);
        state.image = dataurl;
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  const dispatch = useDispatch();

  const [state, setstate] = useState({
    name: product.name,
    description: product.description,
    price: product.price,
    image: product.image,
  });

  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "white",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: "1em",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>Edit Product</div>
      <img
        style={{ width: "200px", height: "200px" }}
        src={selectedImage}
      ></img>
      <br></br>
      <TextField
        id="outlined-basic"
        label="Product Name"
        variant="outlined"
        placeholder="product name"
        value={state.name}
        onChange={(e) => {
          setstate({ ...state, name: e.currentTarget.value });
        }}
      ></TextField>
      <br></br>
      <TextField
        id="outlined-basic"
        label="Description"
        variant="outlined"
        placeholder="product description"
        value={state.description}
        onChange={(e) => {
          setstate({ ...state, description: e.currentTarget.value });
        }}
      ></TextField>
      <br></br>
      <TextField
        id="outlined-basic"
        label="Price"
        variant="outlined"
        placeholder="product price"
        value={state.price}
        onChange={(e) => {
          setstate({ ...state, price: e.currentTarget.value });
        }}
      ></TextField>
      <br></br>
      <Button variant="contained" component="label">
        Use Camera - Gallery Photo
        <input
          type="file"
          id="myfile"
          name="myfile"
          onChange={(e) => {
            handleLoadImage(e);
          }}
          style={{ display: "none" }}
        />
      </Button>

      <AddStockImage
        setselectedImage={setselectedImage}
        locState={state}
      ></AddStockImage>
      {/* <button
          onClick={() => {
                   
                    setshowCamera(!showCamera);
          }}
        >
                Take Photo
        </button> */}

      {/* {showCamera ? <CameraTakeImage></CameraTakeImage> : null} */}
      <br></br>
      <Button
        onClick={() => {
          dispatch({
            type: "UPDATE_PRODUCT_AFTER_EDIT",
            payload: {
              product: {
                ...state,
                show: true,
                id: product.id,
              },
              targetCat: category,
            },
          });
          setShowEditProduct(false);
          dispatch({ type: "GOBACK_STATUS", payload: true });
        }}
      >
        ok
      </Button>

      <Button
        onClick={() => {
          setShowEditProduct(false);
          dispatch({ type: "GOBACK_STATUS", payload: true });
        }}
      >
        cancel
      </Button>
    </div>
  );
};
const AddProductSegment = ({editMode, setShowAddProduct, product, category }) => {
  const [selectedImage, setselectedImage] = useState(null);
  const state2 = useSelector((state) => state);
  console.log(state2.menuData.autocompletes);
  const handleLoadImage = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = (e) => {
      var img = document.createElement("img");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 300;
        var MAX_HEIGHT = 300;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL("image/png");
        console.log(dataurl);
        setselectedImage(dataurl);
        state.image = dataurl;
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  const dispatch = useDispatch();

  const [state, setstate] = useState({
    name: "",
    description: "",
    price: "",
    image: null,
  });
  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "white",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: "1em",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>Edit Product</div>
      <img
        style={{ width: "200px", height: "200px" }}
        src={selectedImage}
      ></img>
      <br></br>
      <Autocomplete 
        floatingLabelFixed={true}
        freeSolo={true}
        id="combo-box-demo"
        onChange={(event, value) => {
          if (value)
            setstate({
              ...state,
              name: value.name,
              description: value ? value.description : "",
            });
        }}
        onInputChange={(event) => {
          setstate({ ...state, name: event.target.value });
          // event.stopPropagation();
        }}
        options={state2.menuData.autocompletes}
        getOptionLabel={(option) => option.name}
        // getOptionSelected={(option) => { setstate({ ...state, description:option.description ?  option.description :''}); }}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField  autoFocus
            {...params}
            label="Product Name"
            variant="outlined"
            //       onChange={(e) => {
            // setstate({ ...state, name: e.currentTarget.value });
            // }
            // }
          />
        )}
      />
      {/* <TextField
        id="outlined-basic"
        label="Product Name"
        variant="outlined"
        placeholder="product name"
        value={state.name}
        onChange={(e) => {
          setstate({ ...state, name: e.currentTarget.value });
        }}
      ></TextField> */}
      <br></br>
      <TextField
        id="outlined-basic"
        label="Description"
        variant="outlined"
        placeholder="product description"
        value={state.description}
        onChange={(e) => {
          setstate({ ...state, description: e.currentTarget.value });
        }}
      ></TextField>
      <br></br>
      <TextField
        id="outlined-basic"
        label="Price"
        variant="outlined"
        placeholder="product price"
        value={state.price}
        onChange={(e) => {
          setstate({ ...state, price: e.currentTarget.value });
        }}
      ></TextField>
      <br></br>
      <Button variant="contained" component="label">
        Use Camera - Gallery Photo
        <input
          type="file"
          id="myfile"
          name="myfile"
          onChange={(e) => {
            handleLoadImage(e);
          }}
          style={{ display: "none" }}
        />
      </Button>

      <AddStockImage
        setselectedImage={setselectedImage}
        locState={state}
      ></AddStockImage>
      {/* <button
          onClick={() => {
                   
                    setshowCamera(!showCamera);
          }}
        >
                Take Photo
        </button> */}

      {/* {showCamera ? <CameraTakeImage></CameraTakeImage> : null} */}
      <br></br>
      <Button
        onClick={() => {
          dispatch({
            type: "ADD_PRODUCT_TO_MENU",
            payload: {
              product: {
                ...state,
                show: true,
                id:
                  localStorage.getItem("userId") +
                  "-" +
                  state2.menuData.menuVenueName +
                  "-" +
                  state2.menuData.menuCity +
                  "-" +
                  new Date().getTime(),
              },
              targetCat: category,
            },
          });
          setShowAddProduct(false);
          dispatch({ type: "GOBACK_STATUS", payload: true });
         if (editMode)
            dispatch(prepareAndUploadMenuItems(true));
          
        }}
      >
        ok
      </Button>

      <Button
        onClick={() => {
          setShowAddProduct(false);
          dispatch({ type: "GOBACK_STATUS", payload: true });
        }}
      >
        cancel
      </Button>
    </div>
  );
};
const AddStockImage = ({ product, setselectedImage, locState }) => {
  const [showThumbs, setShowThumbs] = useState(false);
  const extension = isSafari ? ".png" : ".webp";
  const [searchString, setsearchString] = useState("");
  return (
    <div>
      <Button
        variant="contained"
        style={{ width: "100%" }}
        onClick={() => setShowThumbs(!showThumbs)}
      >
        SELECT STOCK IMAGE
      </Button>
      <br></br>
      {showThumbs ? (
        <div
          style={{
            backgroundColor: "white",
            overflow: "scroll",
            position: "fixed",
            zIndex: 2000001,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: "flex",
            flexWrap: "wrap",
          }}

          // style={{
          //   overflow: "scroll",
          //   display: "flex",

          //   height: "20em",
          //   flexWrap: "wrap",
          // }}
        >
          <TextField autoFocus
                id="outlined-basic"
                label="Search for images"
            value={searchString}
            onChange={(e) => setsearchString(e.currentTarget.value)}
          ></TextField>
          {stockimages
            .filter((stock) =>
              stock.toUpperCase().includes(searchString.toUpperCase())
            )
            .map((imgname) => (
              <img
                onClick={() => {
                  setselectedImage(
                    "https://e-menupull.b-cdn.net/stock_images_normal/" +
                      imgname +
                      extension
                  );
                  locState.image =
                    "https://e-menupull.b-cdn.net/stock_images_normal/" +
                    imgname +
                    extension;
                  setShowThumbs(!showThumbs);
                }}
                key={imgname}
                style={{ width: 150, height: 150 }}
                src={
                  "https://e-menupull.b-cdn.net/stock_images_normal/" +
                  imgname +
                  extension
                }
              ></img>
            ))}
          <Button
            variant="contained"
            color="primary"
            style={{
              position: "fixed",
              zIndex: 300000,
              bottom: 20,
              width: "100%",
            }}
            onClick={() => {
              setShowThumbs(!showThumbs);
            }}
          >
            CANCEL
          </Button>
        </div>
      ) : null}
    </div>
  );
};

// const AddProductSegment = ({ setShowAddProduct, category,setShowGoBack }) => {
//   const [selectedImage, setselectedImage] = useState(null);

//   const handleLoadImage = (e) => {
//     var file = e.target.files[0];
//     var reader = new FileReader();
//     reader.onload = (e) => {
//       var img = document.createElement("img");
//       img.onload = () => {
//         var canvas = document.createElement("canvas");
//         var ctx = canvas.getContext("2d");
//         ctx.drawImage(img, 0, 0);

//         var MAX_WIDTH = 300;
//         var MAX_HEIGHT = 300;
//         var width = img.width;
//         var height = img.height;

//         if (width > height) {
//           if (width > MAX_WIDTH) {
//             height *= MAX_WIDTH / width;
//             width = MAX_WIDTH;
//           }
//         } else {
//           if (height > MAX_HEIGHT) {
//             width *= MAX_HEIGHT / height;
//             height = MAX_HEIGHT;
//           }
//         }
//         canvas.width = width;
//         canvas.height = height;
//         var ctx = canvas.getContext("2d");
//         ctx.drawImage(img, 0, 0, width, height);
//         var dataurl = canvas.toDataURL("image/png");
//         console.log(dataurl);
//         setselectedImage(dataurl);
//         state.image = dataurl;
//       };
//       img.src = e.target.result;
//     };
//     reader.readAsDataURL(file);
//   };
//   const dispatch = useDispatch();
//   const [showCamera, setshowCamera] = useState(false);
//   const [state, setstate] = useState({
//     name: "",
//     description: "",
//     price: "",
//   });
//   return (
//     <div
//       style={{
//         position: "fixed",
//         backgroundColor: "white",
//         top: 0,

//         left: 0,
//         right: 0,
//         padding: "1em",
//       }}
//     >
//       <div>Add Product</div>
//       <img
//         style={{ width: "200px", height: "200px" }}
//         src={selectedImage}
//       ></img>
//       <br></br>
//       <input
//         placeholder="product name"
//         value={state.name}
//         onChange={(e) => {
//           setstate({ ...state, name: e.currentTarget.value });
//         }}
//       ></input>
//       <input
//         placeholder="product description"
//         value={state.description}
//         onChange={(e) => {
//           setstate({ ...state, description: e.currentTarget.value });
//         }}
//       ></input>
//       <input
//         placeholder="product price"
//         value={state.price}
//         onChange={(e) => {
//           setstate({ ...state, price: e.currentTarget.value });
//         }}
//       ></input>
//       <br></br>
//       <label for="myfile">Select a photo from gallery</label>
//       <input
//         type="file"
//         id="myfile"
//         name="myfile"
//         onChange={(e) => {
//           handleLoadImage(e);
//         }}
//       ></input>
//       <AddStockImage setShowGoBack = {setShowGoBack}  setselectedImage={setselectedImage}></AddStockImage>
//       {/* <button
//           onClick={() => {

//                     setshowCamera(!showCamera);
//           }}
//         >
//                 Take Photo
//         </button> */}

//       {/* {showCamera ? <CameraTakeImage></CameraTakeImage> : null} */}
//       <br></br>
//       <button
//         onClick={() => {
//           dispatch({
//             type: "ADD_PRODUCT_TO_MENU",
//             payload: {
//               product: {
//                 ...state,
//                 show: true,
//                 id: new Date().getTime(),
//               },
//               targetCat: category,
//             },
//           });
//           setShowAddProduct(false);
//         }}
//       >
//         ok
//       </button>

//       <button
//         onClick={() => {
//           setShowAddProduct(false);
//         }}
//       >
//         cancel
//       </button>
//     </div>
//   );
// };

const getPdfWithQrCodes = (short_code, tablesArray) => {

  let tables = [];
  tablesArray.forEach(table => tables.push(table.tableCode));


  let config2 = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  };

  /*
var short_code = req.body.short_code;
	var tablesArray = req.body.tablesArray;
	tablesArray = ["b1", "c2", "d1", "k2", "o3", "e9"];

  */
  let g = { short_code: short_code, tablesArray: tables };
  axios
    .post(
      "https://admin.e-menu.co.edit8.com:8000/api/get_qr_codes_pdf",
      g,
      config2
    )
    .then((response) => {
      // alert(response.data.url); 
      window.open('https://e-menupull.b-cdn.net/' + response.data.url, "_blank")
      // console.log("Image posted successfully: ", response);
      // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
    })
    .catch((err) => {
      console.error("Image post failed: ", err);
    });
};

const TablesSegment = () => {
  const CreateTableForm = ({ setshowcreateNewtableForm }) => {
    const [tableCode, settableCode] = useState(null);
    const [tableCodeBatch, settableCodeBatch] = useState(null)
    const [batchNumber, setbatchNumber] = useState(null);
    return (
      <div
        style={{
          position: "fixed",
          display: "flex",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 234234234234,
          backgroundColor: "white",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        Create new table
        <TextField
          autoFocus
          value={tableCode}
          onChange={(e) => {
            if (e.target.value.length < 3) settableCode(e.target.value);
          }}
        ></TextField>

        <Button
          onClick={() => {
            dispatch({ type: "CREATE_NEW_TABLE", payload: tableCode });
            setshowcreateNewtableForm(false);
          }}
        >
          SAVE
        </Button>
        <br></br>
        <br></br>
        <br></br>
        <div>Create Multiple Numbered Tables</div>
        <TextField
           id="outlined-basic"
           label="Batch First Letter"
          value={tableCodeBatch}
          onChange={(e) => {
            if (e.target.value.length < 2) settableCodeBatch(e.target.value);
          }}
        ></TextField>
        <TextField
           id="outlined-basic"
           label="Number Of Tables"
          value={batchNumber}
          onChange={(e) => {
            if (e.target.value.length < 3) setbatchNumber(e.target.value);
          }}
        ></TextField>
         <Button
          onClick={() => {
            dispatch({ type: "BATCH_CREATE_NEW_TABLES", payload: { tableCodeBatch, batchNumber } });
            setshowcreateNewtableForm(false);
          }}
        >
          Batch Create
        </Button>
        <br></br>
        <br></br>
        <Button
          onClick={() => {
           
            setshowcreateNewtableForm(false);
          }}
        >
         Cancel
        </Button>

      </div>
    );
  };

  const tables = useSelector((state) => state.menuData.menuTables);
  const dispatch = useDispatch();
 
  const [showcreateNewtableForm, setshowcreateNewtableForm] = useState(false);
  return (
    <>
      {showcreateNewtableForm ? (
        <CreateTableForm
          setshowcreateNewtableForm={setshowcreateNewtableForm}
        ></CreateTableForm>
      ) : null}
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "4em",
        
        }}
      >
       <Button
          style={{ height: "4em", margin: "10px" }}
          onClick={() => setshowcreateNewtableForm(true)}
        >
          Add New Table
        </Button><div   style={{
          display: "flex",
          width: "100vw",
          height: "4em",
          overflow: "scroll",
        }}>
        {tables &&
          tables.map((table) => (
            <div
              style={{
                display: "flex",
                color:"white",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "row",
                padding:"5px",
                margin: "10px",
                minWidth: "60px",
                
                backgroundColor: "grey",
                borderRadius: "3px",
              }}
            >
              {table.tableCode}
            </div>
          ))}
          </div>
      </div>
    </>
  );
};
const MainDetailsSegment = ({ menuData, editMode }) => {

  const dispatch = useDispatch();
  const menuTables = useSelector(state => state.menuData.menuTables);

  function getPosition(position) {
    console.log(position.coords.latitude, position.coords.longitude);
    dispatch({ type: "SET_COORDINATES", payload: position.coords });
  }
  return (
    <div key="mainDetailsSeg" style={{ paddingTop: "3em" }}>
      {menuTables && menuTables.length ? <Button
        onClick={() => {
          getPdfWithQrCodes(menuData.short_code, menuTables);
        }}
      >
        my qr codes
      </Button> : null}
      <TablesSegment></TablesSegment>
      <TextField
        style={{ width: "100%" }}
        id="outlined-basic"
        label="Venue Name"
        key="venue"
        value={menuData.menuVenueName}
        placeholder="name of restaurant"
        onChange={(e) => {
          dispatch({
            type: "CHANGE_VENUE_NAME",
            payload: e.currentTarget.value.toLowerCase(),
          });
        }}
      ></TextField>

      <TextField
        style={{ width: "100%" }}
        id="outlined-basic"
        label="Venue Place"
        key="city"
        value={menuData.menuCity}
        placeholder="city/place"
        onChange={(e) => {
          dispatch({
            type: "CHANGE_CITY_NAME",
            payload: e.currentTarget.value.toLowerCase(),
          });
        }}
      ></TextField>
      {!editMode ?
        <Button variant="contained"
          color="primary" onClick={() => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(getPosition);
            }
        
          }}>GPS Location</Button> : <Button variant="contained"
          color="primary" onClick={() => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(getPosition);
            }
        
          }}>CHANGE GPS TO CURRENT</Button>}
 
      {/* <AutoComplete2
    style={{width: '90%'}}
    onPlaceSelected={(place) => {
      console.log(place);
    }}
     types={[('address')]}
    componentRestrictions={{country: "mk"}}
/> */}
      <div>Venue Country</div>
      <Dropdown
        key="menuCountry"
        options={countries}
        onChange={(e) => {
          dispatch({
            type: "CHANGE_MENU_COUNTRY",
            payload: e.value,
          });
          console.log(e);
        }}
        value={menuData.menuCountry}
        placeholder="Select an option"
      />
      <div>Menu Currency</div>
      <Dropdown
        key="menuCurrency"
        options={getCurrencies(currencies)}
        onChange={(e) => {
          dispatch({
            type: "CHANGE_MENU_CURRENCY",
            payload: e.value,
          });
          console.log(e);
        }}
        value={menuData.menuCurrency}
        placeholder="Select an option"
      />
      <div>Menu Language</div>
      {!editMode ? (
        <Dropdown
          key="menuLanguage"
          options={getLanguages(languages)}
          onChange={(e) => {
            dispatch({
              type: "CHANGE_MENU_LANGUAGE",
              payload: e.value,
            });
            console.log(e);
          }}
          value={menuData.language}
          placeholder="Select an option"
        />
      ) : (
        <label>{menuData.language}</label>
      )}
    </div>
  );
};

const ProductSegment = (props) => {
  const dispatch = useDispatch();
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [showMoveToCategoryProduct, setShowMoveToCategoryProduct] = useState(
    false
  );

  const menuData = useSelector((state) => state.menuData);

  const categoriesOptions = [];
  menuData.categories.forEach((element) => {
    categoriesOptions.push(element.name);
  });

  return (
    <div style={{ padding: "0.5", paddingLeft: "1em", marginTop: "1em" }}>
      <img
        style={{ height: "1em", width: "1em" }}
        src={props.menuItem.image}
        alt={props.menuItem.name}
      ></img>{" "}
      {props.menuItem.name}{" "}
      <button
        onClick={() => {
          setShowEditProduct(!showEditProduct);
          dispatch({ type: "GOBACK_STATUS", payload: false });
        }}
      >
        edit
      </button>
      {showEditProduct ? (
        <EditProductSegment
          setShowEditProduct={setShowEditProduct}
          category={props.category}
          product={props.menuItem}
        ></EditProductSegment>
      ) : null}
      <button
        onClick={() => {
          dispatch({
            type: "MOVE_PRODUCT_UP",
            payload: {
              productIndex: props.productIndex,
              categoryIndex: props.categoryIndex,
            },
          });
        }}
      >
        ↑
      </button>{" "}
      <button
        onClick={() => {
          dispatch({
            type: "MOVE_PRODUCT_DOWN",
            payload: {
              productIndex: props.productIndex,
              categoryIndex: props.categoryIndex,
            },
          });
        }}
      >
        ↓
      </button>
      {showMoveToCategoryProduct ? (
        <Dropdown
          options={categoriesOptions}
          onChange={(e) => {
            dispatch({
              type: "MOVE_PRODUCT_TO_CATEGORY",
              payload: {
                sourceCategoryIndex: props.categoryIndex,
                sourceProductIndex: props.productIndex,

                menuItem: props.menuItem,
                targetCat: e.value,
              },
            });
            console.log(e);
          }}
          value={props.category.name}
          placeholder="Select an option"
        />
      ) : null}
      <button
        onClick={() => {
          setShowMoveToCategoryProduct(!showMoveToCategoryProduct);
        }}
      >
        move to...
      </button>
      <button
        onClick={() => {
          dispatch({
            type: "DELETE_PRODUCT",
            payload: {
              productIndex: props.productIndex,
              categoryIndex: props.categoryIndex,
            },
          });
        }}
      >
        del
      </button>
    </div>
  );
};

const CategoryEditSegment = ({ category, setshowCategoryEditSegment }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(category.name);
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "white",
        zIndex: 10000000,
        position: "fixed",
      }}
    >
      <TextField
        value={name}
        onChange={(e) => {
          setName(e.currentTarget.value);
        }}
      ></TextField>
      <button
        onClick={() => {
          let pay = { ...category, name: name };
          dispatch({
            type: "SAVE_EDITED_CATEGORY_NAME",
            payload: pay,
          });
          setshowCategoryEditSegment(false);
        }}
      >
        OK
      </button>
    </div>
  );
};
const CategorySegment = ({ category ,editMode}) => {
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showCategoryProductsDropped, setShowCategoryProductsDropped] = useState(false);
  const dispatch = useDispatch();
  const [showCategoryEditSegment, setshowCategoryEditSegment] = useState(false);

  return (
    <div style={{ border: "solid", borderWidth: 0.5, padding: "0.8em" }}  >
      {showCategoryEditSegment ? (
        <CategoryEditSegment
          setshowCategoryEditSegment={setshowCategoryEditSegment}
          category={category}
        ></CategoryEditSegment>
      ) : null}
      <br />
      <div onClick = {()=>setShowCategoryProductsDropped(!showCategoryProductsDropped)}>
        {category.name.toUpperCase()}{" "}
        <button
          onClick={() => setshowCategoryEditSegment(!showCategoryEditSegment)}
        >
          edit
        </button>
        <button
          onClick={() => {
            setShowAddProduct(!showAddProduct);
            dispatch({ type: "GOBACK_STATUS", payload: false });
          }}
        >
          Add Product
        </button>{" "}
        {showAddProduct ? (
          <AddProductSegment editMode = {editMode}
            category={category}
            setShowAddProduct={setShowAddProduct}
          ></AddProductSegment>
        ) : null}
        <button
          onClick={() => {
            dispatch({ type: "MOVE_CATEGORY_UP", payload: category.index });
          }}
        >
          ↑
        </button>{" "}
        <button
          onClick={() => {
            dispatch({ type: "MOVE_CATEGORY_DOWN", payload: category.index });
          }}
        >
          ↓
        </button>{" "}
        <button
          onClick={() => {
            dispatch({ type: "DELETE_CATEGORY", payload: category.name });
          }}
        >
          del
        </button>
      </div>
      <br />
         {showCategoryProductsDropped ? <div >
      {category.items.map((menuItem, productIndex) => (
        <ProductSegment
          key={menuItem.name}
          menuItem={menuItem}
          productIndex={productIndex}
          categoryIndex={category.index}
          category={category}
        ></ProductSegment>
        
      ))} 
      </div> : null}
    </div>
  );
};

const CreateCategory = ({ setShowCreateCategory }) => {
  const dispatch = useDispatch();

  const [state, setstate] = useState("");
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "white",
        zIndex: 10000000,
        position: "fixed",
      }}
    >
      <div>Create category</div>
      <TextField autoFocus
        value={state}
        onChange={(e) => {
          setstate(e.currentTarget.value);
        }}
      ></TextField>
      <Button
        onClick={() => {
          dispatch({ type: "ADD_CATEGORY", payload: state });
          setShowCreateCategory(false);
        }}
      >
        OK
      </Button>
      <Button
        onClick={() => {
          setShowCreateCategory(false);
        }}
      >
        CANCEL
      </Button>
    </div>
  );
};


const prepareAndUploadMenuItemsSuperMarket = (edit) => (dispatch, getState) => {
  console.log(dispatch);

  
    // console.log(values);
    const state = getState();
    let cats = state.menuData.categories;
    let copyOfMenuDataUser = { ...state.menuData.user };
    let copyOfMenuData = { ...state.menuData };
    copyOfMenuDataUser.currentMenus = [];
    copyOfMenuData.user = copyOfMenuDataUser;
  
    let menuDataToBeUploaded = {
      ...copyOfMenuData,
      ...{
        address: state.menuData.menuCity + "~" + state.menuData.menuCountry,
        autoTranslation: false,
        imageLogo: "",
        createdOn: new Date().getTime(),
        // id:
        //   state.menuData.menuVenueName +
        //   "_" +
        //   state.menuData.menuCity +
        //   "_" +
        //   languages.filter(
        //     (lang) => lang.language === state.menuData.originalLanguage
        //   )[0]["639-1"],
        imageLogoBg: "#FFF",
        // language: languages.filter(
        //   (lang) => lang.language === state.menuData.originalLanguage
        // )[0]["639-1"],
        locationLat: state.menuData.locationLat,
        locationLng: state.menuData.locationLng,
        locationRadius: 500,
        name: state.menuData.menuVenueName,
        phone: "",
        short_code: state.menuData.short_code,
        version: 7,
        website: "",
        workingHours: "",
  
        categories: state.menuData.categories,
      },
    };
  
    delete menuDataToBeUploaded.autocompletes;
    let config2 = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };
    menuDataToBeUploaded.userId = localStorage.getItem("userId");
  
      axios
        .post(
          "https://admin.e-menu.co.edit8.com:8000/api/update_menu",
          menuDataToBeUploaded,
          config2
        )
        .then((res) => {
          dispatch({ type: "QR_READY" });
          // alert(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    
  
    dispatch({ type: "PREPARE_MENU_END" });
  


}



const prepareAndUploadMenuItems = (edit) => (dispatch, getState) => {
  console.log(dispatch);
  // return {
  //   type: 'WITHDRAW',
  //  payload: 100
  // }
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  dispatch({ type: "PREPARE_MENU_START" });

  const state = getState();
  let cats = state.menuData.categories;
  let allproducts = [];
  for (let cat of cats) {
    for (let product of cat.items) {
      if (product.image.length > 90) {
        allproducts.push({
          id: product.id,
          name: product.name,
          imageData: product.image,
        });
        if (isSafari) {
          product.image =
            "https://e-menupull.b-cdn.net/" + product.id + ".webp";
        } else {
          product.image = "https://e-menupull.b-cdn.net/" + product.id + ".png";
        }
      } else {
        console.log("less than 90...");
      }
    }
  }
  let promises = [];
  for (let product of allproducts) {
    if (product.imageData.length>200)
   promises.push(new Promise((resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      };

      let fd = new FormData();
      fd.append("name", product.id + ".png");
      fd.append("file", product.imageData);
      if (edit) {
        axios
          .post("https://admin.e-menu.co.edit8.com:8000/api/upload_product", fd, config)
          .then((response) => {
            console.log("Image posted successfully: ", response);
            resolve(response);
            // product.image = "https://emenumk.s3.eu-central-1.amazonaws.com/" + product.id + ".jpg";
          })
          .catch((err) => {
            console.error("Image post failed: ", err);
            reject(err);
          });
      }
    }));
  }

  Promise.all(promises).then(values => 
    { 
    console.log(values);

    let copyOfMenuDataUser = { ...state.menuData.user };
    let copyOfMenuData = { ...state.menuData };
    copyOfMenuDataUser.currentMenus = [];
    copyOfMenuData.user = copyOfMenuDataUser;
  
    let menuDataToBeUploaded = {
      ...copyOfMenuData,
      ...{
        address: state.menuData.menuCity + "~" + state.menuData.menuCountry,
        autoTranslation: false,
        imageLogo: "",
        createdOn: new Date().getTime(),
        // id:
        //   state.menuData.menuVenueName +
        //   "_" +
        //   state.menuData.menuCity +
        //   "_" +
        //   languages.filter(
        //     (lang) => lang.language === state.menuData.originalLanguage
        //   )[0]["639-1"],
        imageLogoBg: "#FFF",
        // language: languages.filter(
        //   (lang) => lang.language === state.menuData.originalLanguage
        // )[0]["639-1"],
        locationLat: state.menuData.locationLat,
        locationLng: state.menuData.locationLng,
        locationRadius: 500,
        name: state.menuData.menuVenueName,
        phone: "",
        short_code: state.menuData.menuVenueName + "_" + state.menuData.menuCity,
        version: 7,
        website: "",
        workingHours: "",
  
        categories: cats,
      },
    };
  
    delete menuDataToBeUploaded.autocompletes;
    let config2 = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };
    menuDataToBeUploaded.userId = localStorage.getItem("userId");
    if (!edit) {
      // menuDataToBeUploaded.user = state.menuData.user;
      menuDataToBeUploaded.deleted = false;
      delete menuDataToBeUploaded.autocompletes;
  
      axios
        .post(
          "https://admin.e-menu.co.edit8.com:8000/api/upload_menu",
          menuDataToBeUploaded,
          config2
        )
        .then((res) => {
          dispatch({ type: "QR_READY" });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(
          "https://admin.e-menu.co.edit8.com:8000/api/update_menu",
          menuDataToBeUploaded,
          config2
        )
        .then((res) => {
          dispatch({ type: "QR_READY" });
          // alert(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    }
  
    dispatch({ type: "PREPARE_MENU_END" });
  })
  .catch(error => { 
    console.error(error.message)
  });
  

  // dispatch({ type: 'PREPARE_MENU_STARTED' });
};

const CreateMenu = (props) => {
  const dispatch = useDispatch();
  const [backToEditShow, setbackToEditShow] = useState(true);
  const [showfinishedButton, setshowfinishedButton] = useState(true);
  const [qrCodeReady, setQrCodeReady] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [showUploadMenu, setShowUploadMenu] = useState(false);
  
  const menuData = useSelector((state) => state.menuData);
  const [showPreparing, setshowPreparing] = useState(false);

  const categoriesOptions = [];
  let history = useHistory();
  useEffect(() => {
    if (!props.edit) {

      // var locale = JSON.parse(localStorage.getItem('locale'));

      // menuData.menuCountry = countriesJson[locale.country.toUpperCase()];
      // menuData.language = locale.language;
      // menuData.menuCurrency = currencies.filter(curr => curr.code.toLowerCase() === locale.currency)[0].code.toLowerCase(); 
      // menuData.categories = [];
      
      // menuData.menuVenueName = "";
      // menuData.menuCity = "";
      dispatch({ type: "RESET_MENU_DATA", payload: {} });
     
    }

   
  }, []);

  menuData.categories.forEach((element) => {
    categoriesOptions.push(element.name);
  });


  const handleForm = () => {
    dispatch(prepareAndUploadMenuItems(props.edit));
  };
  const downloadQR = () => {
    const canvas = document.getElementById(
      "https://e-menu.co.edit8.com/" +
        state.menuData.menuVenueName +
        "_" +
        state.menuData.menuCity
    );
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download =
      state.menuData.menuVenueName + "_" + state.menuData.menuCity + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const state = useSelector((state) => state);

  let editMode = props.edit;

  return (
    <>
      {/* {props.edit ?
        <div style={{ paddingTop: "3em", width: "100%", textAlign: "center" }}>EDIT MENU</div> : null} */}
      {showPreview ? (
        <PreviewMenu
          className="App"
          categories={menuData.categories}
          currency={menuData.menuCurrency}
        ></PreviewMenu>
      ) : state ? (
        <div style={{ padding: "1em" }}>
          <MainDetailsSegment
            menuData={menuData}
            key="MainDetailsSegment"
            editMode={editMode}
          ></MainDetailsSegment>
          {menuData.categories.map((category, index) => (
            <CategorySegment
              editMode = {editMode}
              key={category.name}
              category={{ ...category, index: index }}
            ></CategorySegment>
          ))}
          <br></br>
          <button onClick={() => setShowCreateCategory(!showCreateCategory)}>
            Add Category
          </button>
          <button onClick={() => setShowUploadMenu(!showUploadMenu)}>
            Upload Menu Images
          </button>
          <br></br>

          <br></br>
          {showUploadMenu ? (
            <>
         
            <UploadMenu
              setShowUploadMenu={setShowUploadMenu}
            ></UploadMenu>
            </>
          ) : null}
          {showCreateCategory ? (
            <CreateCategory
              setShowCreateCategory={setShowCreateCategory}
            ></CreateCategory>
          ) : null}
        </div>
      ) : (
        <div>Please go back</div>
      )}

      {showPreview && backToEditShow && state ? (
        <Button
          onClick={() => {
            console.log("PREVIEW NOW!");
            setShowPreview(!showPreview);
            dispatch({ type: "GOBACK_STATUS", payload: showPreview });
          }}
          variant="contained"
          color="primary"
          style={{ zIndex: 10001, position: "fixed", right: 5, bottom: 5 }}
        >
          BACK TO EDIT
        </Button>
      ) : backToEditShow ? (
        <Button
          onClick={() => {
            if (
              state.menuData.menuVenueName.length > 2 &&
              state.menuData.menuCity.length > 1
            ) {
              console.log("PREVIEW NOW!");
              setShowPreview(!showPreview);
              dispatch({ type: "GOBACK_STATUS", payload: showPreview });
            } else {
              window.alert("Please fill venue name and place details!");
            }
          }}
          variant="contained"
          color="primary"
          style={{ zIndex: 10001, position: "fixed", right: 5, bottom: 5 }}
        >
          PREVIEW MENU
        </Button>
      ) : null}

      {showPreview && showfinishedButton ? (
        <Button
          onClick={() => {
            if (
              state.menuData.menuVenueName.length > 2 &&
              state.menuData.menuCity.length > 1
            ) {
              setbackToEditShow(false);
              setshowPreparing(true);
              setshowfinishedButton(false);
              // dispatch({ type: "PREPARE_MENU", payload: {} });

              if (state.menuData.short_code.toUpperCase().includes("SUPER_")) {
                console.log("this is supermarket menu....")
                console.log(state.menuData)
                dispatch(prepareAndUploadMenuItemsSuperMarket(props.edit))
              } else {
                console.log("this is NOT supermarket menu....")
                console.log(state.menuData)
              dispatch(prepareAndUploadMenuItems(props.edit));
              }
            } else {
              window.alert("Please enter venue name and place details first!");
            }
          }}
          variant="contained"
          color="primary"
          style={{ zIndex: 10001, position: "fixed", right: 5, top: 5 }}
        >
          FINISHED? {!props.edit ? "GET YOUR MENU" : "UPDATE MENU"}
        </Button>
      ) : null}
      {showPreparing ? (
        <div
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "black",
            color: "white",
            display: "flex",
            position: "fixed",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1001,
            flexDirection: "column",
          }}
        >
          please wait... preparing your QR menu! Should be ready in less than 20
          seconds!
          <br></br>
          {state.menuData.qrCode ? (
            <QRCode
              id={
                "https://dev.e-menu.co.edit8.com/" +
                state.menuData.menuVenueName +
                "_" +
                state.menuData.menuCity
              }
              value={
                "https://dev.e-menu.co.edit8.com/" +
                state.menuData.menuVenueName +
                "_" +
                state.menuData.menuCity
              }
              size={300}
              level={"H"}
              includeMargin={true}
            />
          ) : null}
          <br></br>
          <button onClick={downloadQR}> Download QR </button>
          <div>
            direct link:
            {"dev.e-menu.co.edit8.com/" +
              state.menuData.menuVenueName +
              "_" +
              state.menuData.menuCity}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              width: "80vw",
              backgroundColor: "white",
              color: "black",
              height: "3em",
            }}
            onClick={() => history.goBack()}
          >
            {" "}
            BACK TO MY MENUS LIST{" "}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateMenu;
