const stockimages = [
  "ALEXANDRIA_RED_WINE",
  "ALEXANDRIA_ROSE",
  "AMSTEL",
  "APPLE_BANDIT",
  "BEER_DRAUGHT",
  "CAPPUCCINO",
  "CIN_ZANO_EXTRA_DRY",
  "CIN_ZANO_ROSSO",
  "COFEE_MACHIATTO",
  "COFFEE_CAPPUCCINO",
  "COFFEE_ESPRESSO",
  "Cappuccino 2",
  "DFRAUGHT_BEER_GLASS",
  "DISARONNO",
  "DRAUGHT_BEER",
  "Espresso",
  "FANTA-LIMON",
  "FANTA_ORANGE",
  "FINLANDIA",
  "FUZE_ICE_TEA",
  "GORDONS_GIN",
  "GORDONS_GIN_PINK",
  "HEINEKEN",
  "JIM_BEAM",
  "JUICE_GRAPEFRUIT",
  "LASKO_BEER",
  "Lavazza_machiatto",
  "MARTINI_BIANCO",
  "MARTINI_ROSSO",
  "MIRINDA_LEMON",
  "NEXT_SOKOVI",
  "Napoleon_chatelle_cognac",
  "PERONI_BOTTLE",
  "SCHWEPPES_PINK",
  "SKYY_BLUE_VODKA",
  "SKYY_RASPBERRY_VODKA",
  "SMIRNOFF_VODKA",
  "TGA_ZA_JUG",
  "VIGOR_VODKA",
  "VIVA_SOKOVI",
  "absolut_vodka",
  "aperol",
  "badel_brandy",
  "badel_rum",
  "beefeater",
  "captain_morgan",
  "carlsberg_small",
  "cedevita_lemon",
  "cedevita_orange",
  "celia",
  "chivas_regal",
  "chocolate",
  "cocacola",
  "cocacola_zero",
  "four_roses",
  "gazoza",
  "grapefruit_drink",
  "grapefruit_orange_cocktail",
  "grey_goose",
  "guarana",
  "havana_club",
  "holsten",
  "hot_Chocolate",
  "jack_daniels",
  "jack_daniels_honey",
  "jack_daniels_honey_2",
  "jameson",
  "jameson_black",
  "jb",
  "jeggermeister",
  "johny_walker_black",
  "johny_walker_red",
  "knjaz_milosh_voda_large",
  "knjaz_milosh_voda_small",
  "kozel",
  "kozel_dark",
  "krali_marko",
  "krombacher",
  "krombacher_dark",
  "krombacher_gold",
  "lavazza_machiatto_small",
  "leffe",
  "leffe_2",
  "life_juice_botltle_2",
  "life_juice_bottle",
  "martini_bianco 2",
  "mirinda_lemon 2",
  "mojito",
  "nescafe",
  "pelinkovac",
  "pepe_lopez_tequila",
  "pepsi",
  "southern_comfort",
  "stock_84",
  "traikovski",
  "traikovsky_brandy",
  "traikovsky_cognac",
  "tuborg_large",
  "tuborg_small",
  "tullamore_dew",
  "vodka_lemon_glass",
  "vranec",
  "zlaten_dab_large",
  "zlaten_dab_small",
  "baileys",
  "cedevita_label",
  "champagne",
  "glasses_champagne",
  "guiness_bottle",
  "guiness_pint",
  "heineken_label",
  "henessy_cognac",
  "henessy_cognac_vsop",
  "jack_daniels_gentlemans_jack_whisky",
  "jameson_label",
  "jb_label",
  "jose_guervo_tequila",
  "lr_lozova_rakija",
  "lr_lozova_rakija_2",
  "lr_lozova_rakija_3",
  "moet_chandon_champagne",
  "pepe_lopez_tequilas",
  "remy_martin_cognac",
  "remy_martin_cognac_2",
  "remy_martin_vsop_cognac",
  "skopsko_draught",
  "skopsko_smooth_bottle",
  "smirnoff_label",
  "smirnoff_label_2",
  "zlaten_dab_label",
  "AMARETTO_SOUR_1",
  "AMARETTO_SOUR_2",
  "AMARETTO_SOUR_3",
  "AMARETTO_SOUR_4",
  "AMARETTO_SOUR_5",
  "AMARETTO_SOUR_6",
  "AMARETTO_SOUR_7",
  "AMARETTO_SOUR_8",
  "APEROL_SPRITZ_1",
  "APEROL_SPRITZ_2",
  "APEROL_SPRITZ_3",
  "APEROL_SPRITZ_4",
  "BLACK_RUSSIAN_1",
  "BLACK_RUSSIAN_2",
  "BLACK_RUSSIAN_3",
  "BLACK_RUSSIAN_4",
  "BLACK_RUSSIAN_5",
  "BLACK_RUSSIAN_6",
  "CAIPIROSKA_1",
  "CAIPIROSKA_2",
  "CAIPIROSKA_3",
  "CAIPIROSKA_4",
  "CAIPIROSKA_5",
  "CAIPIROSKA_6",
  "DAIQUIRI_1",
  "DAIQUIRI_2",
  "DAIQUIRI_3",
  "DAIQUIRI_4",
  "DAIQUIRI_5",
  "DAIQUIRI_6",
  "DAIQUIRI_7",
  "DAIQUIRI_8",
  "FLAMINGO_1",
  "FLAMINGO_2",
  "FLAMINGO_3",
  "FLAMINGO_4",
  "FLAMINGO_5",
  "FROG_1",
  "FROG_2",
  "FROG_3",
  "FROG_4",
  "HAWAIIAN_HAWAII_1",
  "HAWAIIAN_HAWAII_2",
  "HAWAIIAN_HAWAII_3",
  "HAWAIIAN_HAWAII_4",
  "HAWAIIAN_HAWAII_5",
  "HAWAIIAN_HAWAII_6",
  "HAWAIIAN_HAWAII_7",
  "LONG_ISLAND_BLUE",
  "MOJITO_1",
  "MOJITO_2",
  "MOJITO_3",
  "MOJITO_4",
  "MOJITO_5",
  "MOSCOW_MULE",
  "MOSCOW_MULE_2",
  "MOSCOW_MULE_3",
  "MOSCOW_MULE_4",
  "MOSCOW_MULE_5",
  "NEGRONI_1",
  "NEGRONI_2",
  "NEGRONI_3",
  "NEGRONI_4",
  "NEGRONI_5",
  "PINA_COLADA_PINACOLADA_1",
  "PINA_COLADA_PINACOLADA_2",
  "PINA_COLADA_PINACOLADA_3",
  "PINA_COLADA_PINACOLADA_4",
  "SEX_ON_THE_BEACH_1",
  "SEX_ON_THE_BEACH_2",
  "SEX_ON_THE_BEACH_3",
  "SEX_ON_THE_BEACH_4",
  "SEX_ON_THE_BEACH_5",
  "SEX_ON_THE_BEACH_6",
  "TEQUILA_SUNRISE_1",
  "TEQUILA_SUNRISE_10",
  "TEQUILA_SUNRISE_2",
  "TEQUILA_SUNRISE_3",
  "TEQUILA_SUNRISE_4",
  "TEQUILA_SUNRISE_5",
  "TEQUILA_SUNRISE_6",
  "TEQUILA_SUNRISE_7",
  "TEQUILA_SUNRISE_8",
  "TEQUILA_SUNRISE_9",
  "TOM_COLLINS_1",
  "TOM_COLLINS_2",
  "TOM_COLLINS_3",
  "TOM_COLLINS_4",
  "TOM_COLLINS_5",
  "TOM_COLLINS_6",
  "TOM_COLLINS_7",
  "WHITE_RUSSIAN_1",
  "WHITE_RUSSIAN_2",
  "WHITE_RUSSIAN_3",
  "WHITE_RUSSIAN_4",
  "blue_lagoon_1",
  "blue_lagoon_2",
  "blue_lagoon_3",
  "long_ISLAND_5",
  "long_island_1",
  "long_island_2",
  "long_island_3",
  "long_sland_4",
  "AQUA_VIVA",
  "ARCHERS_SCHNAPPS",
  "BALLANTINES",
  "BELUGA_VODKA",
  "BUSHMILLS_IRISH_WHISKEY",
  "CAMPARI",
  "COINTREAU",
  "CORONA_BOTTLE",
  "DEWARS_12",
  "DEWARS_12_2",
  "DEWARS_12_3",
  "DIMPLE",
  "DUVEL",
  "FAMOUS_GROUSE",
  "FAMOUS_GROUSE_2",
  "JAGERMEISTER_2",
  "KAHLUA",
  "KAHLUA_2",
  "PASSOA",
  "PELINKOVAC_2",
  "PLOMARI_OUZO",
  "POGUES_WHISKEY",
  "ROMANA_SAMBUCA",
  "RUSSIAN_STANDARD",
  "SAN_PELLEGRINO_WATER",
  "SAX_PINK",
  "SCHWEPPES_BITTER_LEMON",
  "SCHWEPPES_PINK_2",
  "SCHWEPPES_TONIC_WATER",
  "SKOPSKO_DARK",
  "SKOPSKO_IPL",
  "SPECIAL_VRANEC_TIKVES_2",
  "SPRITE_BOTTLE",
  "STRONGBOW_GOLD_APPLE",
  "STRONGBOW_GOLD_APPLE_2",
  "STRONGBOW_RED_BERRIES",
  "TSANTALI_OUZO",
  "ALEXANDRIA_CUVEE",
  "ALEXANDRIA_CUVEE_2",
  "ALEXANDRIA_CUVEE_3",
  "ALEXANDRIA_CUVEE_4",
  "ALEXANDRIA_ROSE",
  "AMSTEL_5",
  "AMSTEL_6",
  "BACARDI_BREEZER_LEMON",
  "BACARDI_BREEZER_WATERMELON",
  "BACON_PIZZA",
  "BECKS_1",
  "BECKS_2",
  "BELVEDERE_VODKA",
  "BOMBAY_SAPHIRE_2",
  "BOMBAY_SAPHIRE_3",
  "BOPMBAY_SAPHIRE",
  "BUSHMILLS_SINGLE_MALT",
  "CAMINO_REAL_BLANCO_TEQUILA",
  "CAMINO_REAL_GOLD_TEQUILA",
  "CARBONARA_SPAGHETTI",
  "CARDHU_SINGLE_MALT",
  "CHARDONNAY_DEMAINE_LEPOVO",
  "CHERRY_BRANDY",
  "COURVOISIER_1",
  "COURVOISIER_2",
  "COURVOISIER_3",
  "DOBRA_VODA_1",
  "DOM PERIGNON",
  "FORNARIN",
  "GLEN_GRANT_SINGLE_MALT",
  "GRAND_CUVEE_DEMAINE_LEPOVO",
  "IRISH_COFFEE",
  "JOHNNIE_WALKER_PURE_MALT",
  "JURA_SINGLE_MALT",
  "MARTELL_VS",
  "MARTELL_VSOP",
  "MARTINI_ASTI",
  "MARTINI_BRUT",
  "MASTIKA_1",
  "MASTIKA_2",
  "MOCHA_COFFEE",
  "MOCHA_COFFEE_2",
  "MOCHA_COFFEE_3",
  "MOCHA_COFFEE_4",
  "PASSOA_2",
  "PELISTERKA_2",
  "PELISTERKA_3",
  "PELISTERKA_4",
  "PELISTERKA_5",
  "PESTO_PASTA",
  "PINOT_NOIR_DEMAINE_LEPOVO",
  "PIZZAQ_MARGHARITA",
  "PIZZA_CAPRICIOZZA",
  "PIZZA_PARMA",
  "PIZZA_PEPPERONI",
  "PIZZA_PEPPERONI_2",
  "PIZZA_PESTO",
  "QUATRO_FORMAGI_PIZZA",
  "RAMAZZOTTI_LIQUER",
  "RED_BULL",
  "RED_BULL_2",
  "REMY_MARTIN_VS",
  "REMY_MARTIN_XO",
  "RIZOTO_WITH_CHEESE",
  "RIZZOTO_WITH_VEGETABLES",
  "ROSA",
  "ROSA_2",
  "RUSSIAN_STANDARD_PREMIUM",
  "SAN_PELLEGRINO_075",
  "SKOPSKO_5",
  "SKOPSKO_6",
  "SKOPSKO_7",
  "SKOPSKO_SMOOTH_4",
  "SMIRNOFF_ICE_1",
  "SPAGHETTI_BOLOGNESE",
  "STAROPRAMEN_3",
  "STELLA_ARTOIS_1",
  "STELLA_ARTOIS_2",
  "STELLA_ARTOIS_3",
  "Screenshot 2020-07-02 at 21.52.44",
  "Screenshot 2020-07-03 at 19.52.25",
  "Screenshot 2020-07-03 at 19.52.54",
  "TEMJANIKA_1",
  "TEMJANIKA_2",
  "TEMJANIKA_3",
  "TEMJANIKA_4",
  "TEMJANIKA_5",
  "TIKVES_CABERNET_SAUVIGNON_SPECIAL_SELECTION",
  "TIKVES_CABERNET_SAUVIGNON_SPECIAL_SELECTION_2",
  "TIKVES_GRENACHE_BLANC_SPECIAL_SELECTION",
  "TIKVES_MERLOT_SPECIAL_SELECTION",
  "TIKVES_MUSCAT_OTTONEL_SPECIAL_SELECTION",
  "TIKVES_RKACITELI_SPECIAL_SELECTION",
  "TIKVES_SAUVIGNONE_BLANC_SPECIAL_SELECTION",
  "TIKVES_VIOGNIER_SPECIAL_SELECTION",
  "TRAMINEC_1",
  "TRAMINEC_2",
  "TRAMINEC_3",
  "VEGETARIANA_PIZZA",
  "WILD_TURKEY_1",
  "7up",
  "Ali_Oli",
  "BBQ Wrap",
  "BBQ_Pizza",
  "BBQ_Sauce_Skara_Sos",
  "Badem_Almond",
  "Baklava",
  "Barovo Belo",
  "Barovo Cvreno",
  "Bela Rakija",
  "Bela Voda Belo",
  "Bela Voda Crveno",
  "Bieno_Sirenje",
  "Bokal Vino",
  "Bolognese",
  "Burger_Pleskavica",
  "Caj_Tea",
  "Capri",
  "Carbonara",
  "Casa Vino Belo i Crveno",
  "Ceden_Portokal_Fresh_Orange",
  "Cezar_Ceasar",
  "Ckembe_Corba_Ripestew",
  "Dab_Temno_Dab_Dark",
  "Dimena Slanina",
  "Dimena Svinska Vesalica",
  "Diplomatski_Raznic",
  "Domasen_kolbas_Homemade_Sausage",
  "Domat_Salata_Tomato_Salad",
  "Dziger_Liver",
  "Fanta",
  "Feferoni_Hot_Pepers",
  "Fstak_Pistachio",
  "Govedski_Prsut_bovine_ham",
  "Grcka_Salata_Greek_Salad",
  "Hamburger",
  "IN-N-OUT",
  "IN-N-OUT_Pastrmajlija",
  "IN_N_OUT_pizza",
  "Jelen",
  "Juneski_Muskul_Bullok_Muscle",
  "Kamenica",
  "Kavadarka",
  "Kebapi",
  "Kobasica_Sausage",
  "Koftinja od Tikvici",
  "Krap_Carp",
  "Krastavica_salata_Cucumber_Salad",
  "Krastavicki_Pickles",
  "Kremenadla",
  "Lepinji",
  "Lepinji_so_prsut_i_kajmak",
  "Lesnik_Hazelnut",
  "Limonada_Lemonade",
  "Lovecka_Pleskavica",
  "Lukovina_Garlic",
  "Margarita",
  "Med_i_Senf_Honey_Mustard",
  "Mesana_Fresh_Salad",
  "Mesani_apetisani_Mixed_nuts",
  "Mexican Wrap",
  "Mexicana",
  "Milanese",
  "Mimoza_Salad",
  "Mirinda",
  "Nafora",
  "Orada_Gilt_head_bream",
  "Otvoren_batak_Chicken_Hip",
  "Ovci_Kaskaval_Sheep_Cheese",
  "Ovco_sirenje_Sheep_White_Cheese",
  "Palacinki_Pancakes",
  "Parma",
  "Pastrmka_Trout",
  "Pavlaka_Sour_Cream",
  "Pekarski_kompir",
  "Peperoni",
  "Pica_Sendvic_Pizza_Sandwich",
  "Picante",
  "PileskI_Stek_Chicken_Breasts",
  "Pileska_Corba_Chicken_soup",
  "Pileska_Pastrmajlija",
  "Pileski Prsti",
  "Pileski_Krilca_Chicken_Wings",
  "Pileski_Raznic_Chicken_Shashlik",
  "Pileski_Uvijac_Chicken_Roll",
  "Pilesko_kari_sos_Chicken_Curry_Sause",
  "Pilesko_vo_ zelencuk_Chicken_Vegetables",
  "Piroska",
  "Pohovan Kaskaval",
  "Pohovan_Stek_Breaded_chicken_brests",
  "Pohovana Zdenka",
  "Pohovani Tikvici",
  "Polneta pohovana Piperka",
  "Polneta vesalica",
  "Pomfrit_French_Fries",
  "Pomfrit_so_sirenje_French_Fries_Cheese",
  "Popov Belo",
  "Popov Crveno",
  "Popov Roze",
  "Prsuta_sendvic_prosciutto_Sandwich",
  "Rizoto_so_pilesko_Chicken_Risoto",
  "Rizoto_so_zelncuk_Vegetable_Risoto",
  "Roze Casa",
  "Roze Classic",
  "Sampinjoni na Skara",
  "Sarska_Pleskavica_Burger_Cheese",
  "Selsko_Meso",
  "Shashlik",
  "Sirenje na Skara",
  "Slatko_lut_Sos_Sweet_Hot",
  "Smederevka",
  "Sopska_Shopska_Salad",
  "Special Selection Tikves Site",
  "Stara Zolta",
  "Supa_od_zelencuk_Vegetable_soup",
  "Svezo_Rebro_Fresh_rib",
  "Svinska vesalica",
  "Svinski Vrat",
  "Svinski_Raznic_Pork_Chicken_Shashlik",
  "Svinski_Uvijac_Pork_Roll",
  "Svinski_prsut_Pork_Ham",
  "Svinsko File",
  "Svinsko rebro",
  "Taratur_Salad",
  "Tavce_Gravce",
  "Teleska_corba_Beef_Soup",
  "Tikvici na Skara",
  "Topka_Sladoled_Ice_Cream",
  "Toplo_Ladno_Hot_Cold",
  "Torta_Cake",
  "Tost so Sunka",
  "Tost so vrat",
  "Tuna Wrap",
  "Tuna",
  "Tursko_kafe_Turkish_Coffee",
  "Ustipci",
  "Vege",
  "Veleska_Pastrmajlija",
  "Vinjak Rubin",
  "White_sauce_Bel_Sos",
  "Zelena_Piperka_Green_Peper_Salad",
  "Zelka_Salata_Cabbage_Salad",
  "rnarini",
  "prsut",
  "quattro_formaggio",
  "vino"
];

export default stockimages;
